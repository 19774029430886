import React from 'react';
import { View, Platform } from 'react-native';
import { Text, Badge } from 'react-native-elements';
import { DataContext } from '../../contexts/DataContext';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Calendar } from 'react-native-calendars';
import { toastError } from '../../helpers/toast';
import { buildDateWithCompensatedTimezone } from '../../helpers/time';
import utc from 'dayjs/plugin/utc';
import { FontAwesome } from '@expo/vector-icons';
import ActivityIndicator from '../shared/ActivityIndicator';
import { LocaleContext } from '../../contexts/LocaleContext';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const DOT = { key: 'event', color: '#3C3AB1' }

const ScheduleCalendar = ({ event, selected, setSelected }) => {
  const [month, setMonth] = React.useState(dayjs().get('month'));
  const [year, setYear] = React.useState(dayjs().get('year'));
  const [loading, setLoading] = React.useState(false);

  const [scheduleEvents, setScheduleEvents] = React.useState([]);

  const { fetchSchedule } = React.useContext(DataContext);
  const { t } = React.useContext(LocaleContext);

  React.useEffect(() => {
    (async () => {
      try {
        const start = dayjs(new Date(year, month, 1));
        const end = start.endOf('month').hour(0).minute(0).second(0).millisecond(0);
        setLoading(true);
        const result = await fetchSchedule({ eventId: event.id, startDate: start.format('YYYY-MM-DD'), endDate: end.format('YYYY-MM-DD') });
        setScheduleEvents(result.schedule_events.map((e) => ({
          ...e,
          start: buildDateWithCompensatedTimezone(e.start),
        })));
      } catch (error) {
        console.log(`Error: ${error}`);
        toastError({
          title: t('messages.fetch_tickets_failed_title'),
          message: t('messages.fetch_tickets_failed'),
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [month, year]);

  const markedDates = {};
  for(let i = 0; i < scheduleEvents.length; i++) {
    const scheduleEvent = scheduleEvents[i];

    const d = dayjs.utc(scheduleEvent.start);

    if (markedDates[d.format('YYYY-MM-DD')]) {
      markedDates[d.format('YYYY-MM-DD')].dots.push({ key: `${markedDates[d.format('YYYY-MM-DD')].dots.length+1}`, color: '#3C3AB1' });
    } else {
      markedDates[d.format('YYYY-MM-DD')] = {
        marked: true,
        selected: selected && selected.split(',').some((sd) => d.format('YYYY-MM-DD') === dayjs.utc(sd).format('YYYY-MM-DD')),
        dots: [{ key: '1', color: '#3C3AB1' }],
      };
    }
  }

  return (
    <View>
      <Calendar
        renderArrow={(direction) => direction === 'right' ? <FontAwesome name="angle-right" size={24} color="black" /> : <FontAwesome name="angle-left" size={24} color="black" />}
        enableSwipeMonths={true}
        onMonthChange={(month) => {
          setMonth(month.month - 1);
          setYear(month.year);
          setScheduleEvents([]);
        }}
        style={{

        }}
        markedDates={markedDates}
        onDayPress={(day) => {
          // console.log(`day press: ${JSON.stringify(day)}`);
          const selectedEvents = scheduleEvents.filter((e) => dayjs(e.start).format('YYYY-MM-DD') === dayjs(day.dateString).format('YYYY-MM-DD'));
          setSelected(
            selectedEvents
              .map((e) => dayjs(e.start).format('YYYY-MM-DD[T]HH:mm:[00]'))
              .join(',')
          );
        }}
        markingType={'multi-dot'}
        renderHeader={(date) => (
          <View style={{flexDirection:'row'}}>
            <Text>{dayjs(new Date(year, month, 1)).format('MMMM YYYY')}</Text>
            {loading && <ActivityIndicator style={{marginLeft:5}} />}
          </View>
        )}
      />
    </View>
  );
};

export default ScheduleCalendar;
