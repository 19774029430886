import React from 'react';
import { Platform } from 'react-native';
import { AuthContext } from '../contexts/AuthContext';
import { storeData, fetchData } from '../helpers/storage';
import { CAMERA_TYPE_BACK, CAMERA_TYPE_FRONT } from '../components/scan/CameraScanner';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advanced from "dayjs/plugin/advancedFormat";
// import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import en from '../../assets/locales/en.json';
import de from '../../assets/locales/de.json';
import fr from '../../assets/locales/fr.json';
import es from '../../assets/locales/es.json';
import ja from '../../assets/locales/ja.json';

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(advanced);

// let defaultLocale = 'en';
// try {
//   defaultLocale = Localization.locale;
// } catch (error) {
//   console.log(`Unable to set default locale from device localization: ${error}`);
//   defaultLocale = 'en';
// }
export const defaultLocale = 'en-US';
export const defaultLanguage = (defaultLocale || 'en').split('-')[0];
export const getUserEmail = () => {
  const { userEmail } = React.useContext(AuthContext);
  return userEmail
}

export const translations = {
  en,
  fr,
  de,
  es,
  ja,
};

export const availableLocales = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Spanish', value: 'es' },
  { label: 'Japanese', value: 'ja' },
];

i18n.translations = translations;
console.log(`Loading app with locale ${defaultLocale}`);
i18n.locale = defaultLocale;
i18n.fallbacks = true;

export const LocaleContext = React.createContext();

export const LocaleProvider = ({ children }) => {
  const [language, setLanguage] = React.useState(defaultLanguage);

  const storeLanguage = async (newLanguage) => {
    console.log(`Storing language preference: ${newLanguage}`);
    await storeData(`Language`, `${newLanguage}`);
  };

  const loadLanguage = async () => {
    console.log(`Loading language preference`);
    const lang = (await fetchData(`Language`)) || defaultLanguage;
    i18n.locale = lang;
    setLanguage(lang);
  };

  React.useEffect(() => {
    loadLanguage();
  }, []);

  const localeContext = {
    language,
    setLanguage: async (v) => {
      await storeLanguage(v);
      i18n.locale = v;
      setLanguage(v);
    },
    defaultLocale,
    defaultLanguage,
    availableLocales,
    t: (key, args) => i18n.t(key, args),
    formatDateAndTime: (date, tz='Etc/UTC', format='long') => {
      let formattedDate;
      const userEmail = getUserEmail();
      const inheritTimezone = userEmail && localStorage.getItem(`EveyCheckin:Settings:${userEmail}:autoTimezone`);      
      const currentTimezone = userEmail && localStorage.getItem(`EveyCheckin:Settings:${userEmail}:timezone`);
      if(inheritTimezone && tz) {
        if(inheritTimezone === "true") {
          formattedDate = dayjs(date)?.tz(tz)?.format('lll z');
        } else if(inheritTimezone !== "true" && currentTimezone) {
          formattedDate = dayjs(date)?.tz(currentTimezone)?.format('lll z');
        } else {
          formattedDate = dayjs(date)?.format('lll z');
        }
        return date && formattedDate ? formattedDate : 'n/a'
      } else {
        formattedDate = tz ? dayjs(date)?.tz(tz)?.format('lll z') : dayjs(date)?.format('lll z');
        return date && formattedDate ? formattedDate : 'n/a'
      }

    }
  };

  return (
    <LocaleContext.Provider value={localeContext}>
      {children}
    </LocaleContext.Provider>
  );
};
