import React from 'react';
import { notifyError } from '../../helpers/airbrake';
import {
  getUnhandledPromiseRejectionTracker,
  setUnhandledPromiseRejectionTracker,
} from 'react-native-promise-rejection-utils';

const prevTracker = getUnhandledPromiseRejectionTracker();
setUnhandledPromiseRejectionTracker((id, error) => {
  console.error('Unhandled promise rejection!', id, error);
  notifyError(error, {}, { component: 'unhandled-rejection-handler' });

  if (prevTracker !== undefined) {
    prevTracker(id, error)
  }
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: (props || {}).userEmail,
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    notifyError(error,
      {
        info: info,
        userEmail: this.state.userEmail,
      },
      { component: 'error-boundary' }
    );
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return <h1>Something went wrong.</h1>;
    // }
    return this.props.children;
  }
}

export default ErrorBoundary;
