import React from 'react';
import ApiDataProvider from '../db/ApiDataProvider';
import { AuthContext } from '../contexts/AuthContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { storeData } from '../helpers/storage';

export const DataContext = React.createContext();

const PER_PAGE = 50;

const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => obj1[key] === obj2[key]);

export const DataProvider = ({ children, userEmail }) => {
  const [events, setEvents] = React.useState(null);
  const [eventCache, setEventCache] = React.useState({});
  const [loadingEvent, setLoadingEvent] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState(null);
  const [attendees, setAttendees] = React.useState(null);
  const [attendeeCache, setAttendeeCache] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [scanning, setScanning] = React.useState(false);
  const [attendeesCursor, setAttendeesCursor] = React.useState(null);

  const { defaultSort } = React.useContext(SettingsContext);
  const initialAttendeesFilters = { search: '', sort: defaultSort || 'ticket', checkinStatus: 'all', ticketStatus: 'active', ticketType: 'all', scheduledOn: '' };

  const [attendeesFilters, setAttendeesFilters] = React.useState(initialAttendeesFilters);

  const { signOut } = React.useContext(AuthContext);

  const provider = ApiDataProvider();

  const hasMoreAttendees = !!attendeesCursor;

  const findEvent = (eventId) => {
    // return events && events.find((e) => e.id == eventId);
    if (eventCache[eventId.toString()]) {
      return eventCache[eventId.toString()];
    }
    return null;
  };

  const fetchEvents = async ({ useCache } = {}) => {
    const _useCache = useCache === undefined || useCache === true;
    if (_useCache && events != null) {
      return events;
    }
    setLoading(true);
    try {
      const response = await provider.fetchEvents();
      // console.log(`Fetched events: ${JSON.stringify(response)}`);
      setEvents(response);
    } catch (error) {
      if (error.name === 'AuthenticationRequiredError') {
        console.log(`[fetchEvents] Authentication failed, logging out`);
        signOut();
      } else {
        console.log(`Error: ${error.name}: ${error.message}`);
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  const fetchEvent = async ({ eventId, useCache, skipNoAuthSignOut }) => {
    const _useCache = useCache === undefined || useCache === true;
    if (_useCache) {
      const event = findEvent(eventId);
      if (event) {
        return event;
      }
    }
    setLoadingEvent(true);
    try {
      const response = await provider.fetchEvent({ eventId });
      // console.log(`Fetched event: ${JSON.stringify(response)}`);
      setEventCache({
        ...eventCache,
        [response.id]: response,
      });
      if(response?.timezone) {
        if(localStorage.getItem(`EveyCheckin:Settings:${userEmail}:timezone`) === null) {
          await storeData(`Settings:${userEmail}:timezone`, `${response?.timezone}`);
        }
      }
      return response;
    } catch (error) {
      if (!skipNoAuthSignOut && error.name === 'AuthenticationRequiredError') {
        console.log(`[fetchEvent] Authentication failed, logging out`);
        signOut();
      } else {
        throw error;
      }
    } finally {
      setLoadingEvent(false);
    }
  }

  const fetchAttendees = async ({ eventId, search, checkinStatus, ticketStatus, ticketType, scheduledOn, sort, useCache, skipNoAuthSignOut }) => {
    const _useCache = useCache === undefined || useCache === true;
    if (_useCache && attendees != null) {
      return attendees;
    }
    setLoading(true);
    try {
      const response = await provider.fetchAttendees({ eventId, search, checkinStatus, ticketStatus, ticketType, scheduledOn, sort, perPage: PER_PAGE });
      // console.log(`Fetched attendees: ${JSON.stringify(response)}`);
      setAttendees(response.body);
      setAttendeesCursor(response.headers['x-evey-next-cursor']);
    } catch (error) {
      if (!skipNoAuthSignOut && error.name === 'AuthenticationRequiredError') {
        console.log(`[fetchAttendees] Authentication failed, logging out`);
        signOut();
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  const fetchAttendeesNextPage = async ({ eventId, search, checkinStatus, ticketStatus, ticketType, scheduledOn, sort }) => {
    setLoadingMore(true);
    try {
      const response = await provider.fetchAttendees({ eventId, cursor: attendeesCursor });
      // console.log(`Fetched more attendees (${attendeesCursor}): ${JSON.stringify(response)}`);
      setAttendees([
        ...attendees,
        ...response.body,
      ]);
      setAttendeesCursor(response.headers['x-evey-next-cursor']);
    } catch (error) {
      if (error.name === 'AuthenticationRequiredError') {
        console.log(`[fetchAttendeesNextPage] Authentication failed, logging out`);
        signOut();
      } else {
        throw error;
      }
    } finally {
      setLoadingMore(false);
    }
  }

  const findAttendee = (attendeeId) => {
    if (attendeeCache[attendeeId.toString()]) {
      return attendeeCache[attendeeId.toString()];
    }
    return null;
  };

  const findOrCacheAttendee = (attendeeId, attendee, listObject=true) => {
    if (attendeeCache[attendeeId.toString()]) {
      return attendeeCache[attendeeId.toString()];
    } else {
      if (listObject) {
        if (attendees != null) {
          const index = attendees.findIndex((a) => a.id == attendee.id);
          if (index !== -1) {
            attendees[index] = attendee;
          }
        }
      } else {
        attendeeCache[attendeeId.toString()] = attendee;
      }
      return attendee;
    }
  };

  const fetchAttendee = async ({ eventId, attendeeId, useCache, skipNoAuthSignOut }) => {
    const _useCache = useCache === undefined || useCache === true;
    if (_useCache) {
      const attendee = findAttendee(attendeeId);
      if (attendee) {
        return attendee;
      }
    }
    setLoading(true);
    try {
      const response = await provider.fetchAttendee({ eventId, attendeeId });
      // console.log(`Fetched attendee: ${JSON.stringify(response)}`);
      setAttendeeCache({
        ...attendeeCache,
        [response.id]: response,
      });
      setLoading(false);
      return response;
    } catch (error) {
      if (!skipNoAuthSignOut && error.name === 'AuthenticationRequiredError') {
        console.log(`[fetchAttendee] Authentication failed, logging out`);
        signOut();
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  const checkinAttendee = async ({ eventId, attendeeId }) => {
    setSaving(true);
    try {
      const response = await provider.checkinAttendee({ eventId, attendeeId });
      // console.log(`Checked in attendee: ${JSON.stringify(response)}`);
      setAttendeeCache({
        ...attendeeCache,
        [response.id]: response,
      });
      if (attendees != null) {
        const index = attendees.findIndex((a) => a.id == response.id);
        if (index !== -1) {
          attendees[index] = response;
        }
      }
    } catch (error) {
      if (error.name === 'AuthenticationRequiredError') {
        console.log(`[checkinAttendee] Authentication failed, logging out`);
        signOut();
      } else {
        throw error;
      }
    } finally {
      setSaving(false);
    }
  }

  const cancelCheckinAttendee = async ({ eventId, attendeeId }) => {
    setSaving(true);
    try {
      const response = await provider.cancelCheckinAttendee({ eventId, attendeeId });
      // console.log(`Checked in cancelled for attendee: ${JSON.stringify(response)}`);
      setAttendeeCache({
        ...attendeeCache,
        [response.id]: response,
      });
      if (attendees != null) {
        const index = attendees.findIndex((a) => a.id == response.id);
        if (index !== -1) {
          attendees[index] = response;
        }
      }
    } catch (error) {
      if (error.name === 'AuthenticationRequiredError') {
        console.log(`[cancelCheckinAttendee] Authentication failed, logging out`);
        signOut();
      } else {
        throw error;
      }
    } finally {
      setSaving(false);
    }
  }

  const scanTicket = async ({ code }) => {
    setScanning(true);
    try {
      const response = await provider.scanTicket({ code });
      // console.log(`Checked in attendee: ${JSON.stringify(response)}`);
      return response;
    } catch (error) {
      if (error.name === 'AuthenticationRequiredError') {
        console.log(`[scanTicket] Authentication failed, logging out`);
        return [];
      } else {
        throw error;
      }
    } finally {
      setScanning(false);
    }
  }

  const fetchSchedule = async ({ eventId, startDate, endDate }) => {
    try {
      const response = await provider.fetchSchedule({ eventId, startDate, endDate });
      // console.log(`Fetched schedule: ${JSON.stringify(response)}`);
      return response;
    } catch (error) {
      if (error.name === 'AuthenticationRequiredError') {
        console.log(`[fetchSchedule] Authentication failed, logging out`);
        signOut();
      } else {
        throw error;
      }
    } finally {
    }
  };

  const updateAttendee = async ({ eventId, attendeeId, params }) => {
    setSaving(true);
    try {
      const response = await provider.updateAttendee({ eventId, attendeeId, params });
      // console.log(`Updated attendee: ${JSON.stringify(response)}`);
      setAttendeeCache({
        ...attendeeCache,
        [response.id]: response,
      });
      if (attendees != null) {
        const index = attendees.findIndex((a) => a.id == response.id);
        if (index !== -1) {
          attendees[index] = response;
        }
      }
    } catch (error) {
      if (error.name === 'AuthenticationRequiredError') {
        console.log(`[updateAttendee] Authentication failed, logging out`);
        signOut();
      } else {
        throw error;
      }
    } finally {
      setSaving(false);
    }
  };

  const clearData = async () => {
    setEvents(null);
    await clearAttendeeData();
  };

  const clearAttendeeData = async () => {
    setAttendees(null);
    setAttendeeCache({});
    setAttendeesCursor(null);
    setAttendeesFilters(initialAttendeesFilters);
  };

  const resetAttendeeFilters = () => {
    setAttendeesFilters(initialAttendeesFilters);
  };

  const dataContext = {
    fetchEvents,
    fetchEvent,
    selectedEvent,
    selectEvent: (eventId) => {
      // console.log(`Selecting event: ${eventId}`);
      if (selectedEvent != eventId) {
        // console.log(`Clearing existing event attendee cache: ${selectedEvent}`);
        clearAttendeeData();
      }
      setSelectedEvent(eventId);
    },
    fetchAttendees,
    fetchAttendee,
    checkinAttendee,
    cancelCheckinAttendee,
    scanTicket,
    findAttendee,
    findOrCacheAttendee,
    findEvent,
    events,
    attendees,
    loading,
    clearData,
    saving,
    hasMoreAttendees,
    fetchAttendeesNextPage,
    loadingMore,
    loadingEvent,
    attendeesFilters,
    setAttendeesFilter: (name, value) => setAttendeesFilters({ ...attendeesFilters, [name]: value }),
    resetAttendeeFilters,
    fetchSchedule,
    updateAttendee,
  };

  return (
    <DataContext.Provider value={dataContext}>
      {children}
    </DataContext.Provider>
  );
}
