import React from 'react';
import { StyleSheet } from 'react-native';
import { Button as NativeButton } from 'react-native-elements';
import { theme } from '../../../app.json';
import PrimaryButton from './PrimaryButton';

const Button = ({ title, status, style, containerStyle , onPress, loading, icon, disabled }) => {

  let backgroundColor = '#f1f4f5';
  if (status === 'critical') {
    backgroundColor = '#CC170D';
  } else if (status === 'info') {
    backgroundColor = '#E6F7FB';
  } else if (status === 'success') {
    backgroundColor = theme.checkinColor;
  }

  return (
    <PrimaryButton
      containerStyle={containerStyle}
      title={title}
      buttonStyle={{ backgroundColor: backgroundColor }}
      onPress={onPress}
      loading={loading}
      icon={icon}
      disabled={disabled}
    />
  );
};

export default Button;
