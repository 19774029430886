import React from 'react';
import { ActivityIndicator as RNActivityIndicator, Platform } from 'react-native';

const ActivityIndicator = (props) => {
  let _props = props ? {...props} : {};
  if (Platform.OS === 'android') {
    _props.animating = true;
    if (!props.color) {
      _props.color = 'black';
    }
  }

  return (
    <RNActivityIndicator
      {..._props}
    />
  );
};

export default ActivityIndicator;
