import React from 'react';
import { SafeAreaView, View, Image, Pressable, ScrollView, Linking, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Text, Button, Input } from 'react-native-elements';
import { APP_URL, CHECKIN_API_KEY } from '../helpers/api';
import Banner from '../components/shared/Banner';
import HeaderBackButton from '../components/shared/HeaderBackButton';
import { AuthContext } from '../contexts/AuthContext';
import TextInput from '../components/shared/TextInput';
import PrimaryButton from '../components/shared/PrimaryButton';
import { LocaleContext } from '../contexts/LocaleContext';

const ResetPasswordScreen = ({ navigation, route }) => {
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');

  const passwordConfirmInputRef = React.useRef();

  let { token } = route.params;

  const { isLoggedIn } = React.useContext(AuthContext);
  const { t } = React.useContext(LocaleContext);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <HeaderBackButton
          onPress={()=>{
            const index = navigation.dangerouslyGetState().index;
            if (index == 0) {
              navigation.reset({
                index: 0,
                routes: [isLoggedIn ? { name: 'Main' } : { name: 'Login' }]
              });
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
    });
  }, [navigation]);

  const submit = async () => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    const response = await fetch(`${APP_URL}/checkin/api/sessions/reset_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${CHECKIN_API_KEY}`,
      },
      body: JSON.stringify({ token, password, password_confirm: passwordConfirm }),
    });
    const result = await response.json();
    if (result.error) {
      setErrorMessage(result.error);
    } else if (result.success) {
      setSuccessMessage(result.success);
    }
    setLoading(false);
  };

  if (successMessage) {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: '#fff', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <View style={{ paddingLeft: 28, paddingRight: 28, width: '100%' }}>
          <Text style={{ marginBottom: 20, marginTop: 36, textAlign: 'center', fontSize: 24, lineHeight: 44, fontWeight: '500' }}>{t('reset_password.reset_your_password')}</Text>
          <Banner status='success' containerStyle={{ marginBottom: 20 }}>
            <Text style={{ textAlign: 'center' }}>{successMessage}</Text>
          </Banner>
          <PrimaryButton
            title={t('reset_password.login')}
            type="solid"
            onPress={() => navigation.reset({
              index: 0,
              routes: [{ name: 'Login' }]
            })}
          />
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
      <KeyboardAwareScrollView contentContainerStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
        <View style={{ paddingLeft: 28, paddingRight: 28, width: '100%' }}>
          <Text style={{ marginBottom: 20, marginTop: 36, textAlign: 'center', fontSize: 24, lineHeight: 44, fontWeight: '500' }}>{t('reset_password.reset_your_password')}</Text>
          <Text style={{ marginBottom: 40, textAlign: 'center' }}>{t('reset_password.instructions')}</Text>
          <TextInput
            placeholder=""
            label={t('login.password')}
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            autoCompleteType='password'
            secureTextEntry={true}
            textContentType='password'
            returnKeyType='next'
            autoCapitalize='none'
            onSubmitEditing={() => passwordConfirmInputRef.current.focus()}
          />
          <TextInput
            placeholder=""
            label={t('reset_password.password_confirm')}
            value={passwordConfirm}
            onChangeText={setPasswordConfirm}
            secureTextEntry
            autoCompleteType='password'
            secureTextEntry={true}
            textContentType='password'
            returnKeyType='go'
            autoCapitalize='none'
            ref={passwordConfirmInputRef}
            onSubmitEditing={() => submit()}
          />
          <PrimaryButton
            title={t('common.submit')}
            onPress={() => submit()}
            loading={loading}
          />
          {errorMessage ? (
            <Text style={{ color: 'red', marginTop: 20, textAlign: 'center' }}>{errorMessage}</Text>
          ) : (
            null
          )}
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

export default ResetPasswordScreen;
