import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import CheckInContainer from './CheckInContainer';
import ScanScreen from './ScanScreen';
import SettingsScreen from './SettingsScreen';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
//import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { MaterialIcons } from '@expo/vector-icons';
import { DataProvider } from '../contexts/DataContext';
import { ScannerProvider } from  '../contexts/ScannerContext';
import { SettingsProvider } from '../contexts/SettingsContext';
import { LocaleContext } from '../contexts/LocaleContext';

const Tab = createBottomTabNavigator();

const TabContainer = ({ navigation, userEmail }) => {
  const { t, language } = React.useContext(LocaleContext);

  console.log(`language: ${language}`);
  console.log(`t('navigation.settings'): ${t('navigation.settings')}`);

  return (
    <SettingsProvider>
      <DataProvider userEmail={userEmail}>
        <ScannerProvider navigation={navigation}>
          <Tab.Navigator
            screenOptions={({ route }) => ({
              tabBarIcon: ({ focused, color, size }) => {
                if (route.name === 'Check-In') {
                  return <MaterialCommunityIcons name='playlist-check' size={size} color={color} />;
                } else if (route.name === 'Scan') {
                  return <MaterialCommunityIcons name='qrcode-scan' size={size} color={color} />;
                } else if (route.name === 'Settings') {
                  return <MaterialIcons name='settings' size={size} color={color} />;
                }
              },
            })}
            // tabBarOptions={{
            //   activeTintColor: '#000000',
            //   inactiveTintColor: 'gray',
            // }}
          >
              <Tab.Screen name="Check-In" component={CheckInContainer} options={{ title: t('navigation.checkin') }} />
              <Tab.Screen name="Scan" component={ScanScreen} options={{ title: t('navigation.scan') }} />
              <Tab.Screen name="Settings" component={SettingsScreen} options={{ title: t('navigation.settings') }} />
          </Tab.Navigator>
        </ScannerProvider>
      </DataProvider>
    </SettingsProvider>
  );
};

export default TabContainer;
