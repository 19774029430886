import { fetchData, storeData, removeData } from './storage';
import { AuthenticationRequiredError, UnknownApiError, ApiResourceNotFoundError, ApiConflict } from './errors';
import jwt_decode from "jwt-decode";

// dev
// export const APP_URL = "https://evey-core-dev-jason.ngrok.io";
// export const CHECKIN_API_KEY = 'aa35f9a7d5bc6d6b1733f2acbe1dc86c';

// prod
export const APP_URL = "https://app.eveyevents.com";
export const CHECKIN_API_KEY = 'acf23294d47c6829700c0064571d3a96';

export const logout = async () => {
  await removeData('userToken');
  await removeData('refreshToken');
};

export const loginRequest = async ({ email, password }) => {
  const response = await fetch(`${APP_URL}/checkin/api/sessions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Token ${CHECKIN_API_KEY}`,
    },
    body: JSON.stringify({ email, password }),
  });

  if (response.status === 401) {
    throw new AuthenticationRequiredError('Failed to login with email and password');
  } else if (response.status === 404) {
    throw new ApiResourceNotFoundError('Not found');
  } else if (response.status === 409) {
    throw new ApiConflict('Conflict');
  } else if (response.status === 200) {
    const newToken = response.headers.get('x-evey-checkin-access-token');
    const newRefreshToken = response.headers.get('x-evey-checkin-refresh-token');
    if (newToken) {
      await storeData('userToken', newToken);
      await storeData('refreshToken', newRefreshToken);
      return {
        ...(await response.json()),
        access_token: newToken,
      };
    }
  } else {
    throw new UnknownApiError('Unknown error when tring to login');
  }
};

const request = async ({ path, method, data, attempt, returnOnlyBody }) => {
  const accessToken = await fetchData('userToken');
  // console.log(`[api] access token: ${accessToken}`);
  if (!accessToken) {
    throw new AuthenticationRequiredError('Request failed due to missing access token');
  }

  let email;
  try {
    const decodedToken = jwt_decode(accessToken);
    email = decodedToken.email;
  } catch (error) {
    console.log(`Unale to decode token on api request: ${error}`);
    throw new AuthenticationRequiredError('Request failed due to missing access token');
  }

  const response = await fetch(`${APP_URL}${path}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Evey-Checkin-Token': accessToken,
      'Authorization': `Token ${CHECKIN_API_KEY}`,
    },
    ...(data && {
      body: JSON.stringify(data),
    }),
  });

  if (response.status == 401 || response.status == 403) {
    console.log(
      `Login required for checkin app access, attempting refresh: ${path}`
    );
    const refreshToken = await fetchData('refreshToken'); // TODO: use secure store
    // await removeData('refreshToken');
    // console.log(`Attempting refresh with token: ${refreshToken}`);
    // await new Promise(r => setTimeout(r, 5000));
    if (refreshToken) {
      const refreshResponse = await fetch(`${APP_URL}/checkin/api/sessions/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Token ${CHECKIN_API_KEY}`,
        },
        body: JSON.stringify({
          email,
          refresh_token: refreshToken,
        }),
      });

      if (refreshResponse.status === 200) {
        const newToken = refreshResponse.headers.get('x-evey-checkin-access-token');
        const newRefreshToken = refreshResponse.headers.get('x-evey-checkin-refresh-token');
        await storeData('userToken', newToken);
        await storeData('refreshToken', newRefreshToken);
        console.log(`Refresh for ${email} successful: ${newToken}`);
        if (attempt && attempt > 5) {
          throw new AuthenticationRequiredError('Request failed due to missing authentication');
        }
        return request({ email, path, method, data, attempt: (attempt || 1) + 1, returnOnlyBody });
      }
    }

    throw new AuthenticationRequiredError('Request failed due to missing authentication');
  } else if (response.status === 404) {
    throw new ApiResourceNotFoundError('Not found');
  } else if (response.status === 409) {
    throw new ApiConflict('Conflict');
  } else {
    try {
      const result = await response.json();
      if (returnOnlyBody) {
        return result;
      } else {
        return {
          body: result,
          headers: Object.fromEntries(response.headers.entries()),
        }
      }
    } catch (error) {
      console.log(`Unable to parse response: ${error}`);
      // TODO: to errbit
      throw new UnknownApiError('Error while communicating with Evey');
    }
  }
};

export const post = async (path, data, returnOnlyBody=true) => {
  return await request({ path, method: 'POST', data, returnOnlyBody });
};

export const get = async (path, params, returnOnlyBody=true) => {
  const pathToUse = params
    ? `${path}?${new URLSearchParams(params).toString()}`
    : path;
  return await request({ path: pathToUse, method: 'GET', returnOnlyBody });
};

export const put = async (path, data, returnOnlyBody=true) => {
  return await request({ path, method: 'PUT', data, returnOnlyBody });
};

export const del = async (path, data, returnOnlyBody=true) => {
  return await request({ path, method: 'DELETE', data, returnOnlyBody });
};
