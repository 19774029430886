import React from 'react';
import { View } from 'react-native';
import { Button, Text } from 'react-native-elements';
import AttendeeListItem from './AttendeeListItem';
import { DataContext } from '../../contexts/DataContext';
import { LocaleContext } from '../../contexts/LocaleContext';

const AttendeeOrderSection = ({ event, attendee, navigation }) => {
  const { findOrCacheAttendee } = React.useContext(DataContext);
  const { t } = React.useContext(LocaleContext);

  if (!event || !attendee) {
    return null;
  }

  const order_attendees = attendee.order && attendee.order.attendees && attendee.order.attendees.filter((a) => !a.cancelled_at && a.id != attendee.id).map((a) => findOrCacheAttendee(a.id, a));

  if (order_attendees && order_attendees.length > 0) {
    return (
      <View>
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 18}}>
          <Text style={{ fontSize: 20, fontWeight: 'bold', color: '#161616' }}>{t('attendee.order')}{attendee.order.name ? ` ${attendee.order.name}` : ''}</Text>
          <Text style={{ fontSize: 12, fontWeight: 'bold', color: '#7f7f7f' }}>{t('attendee.other_tickets_order')}</Text>
        </View>
        <View style={{ marginTop: 20 }}>
          {order_attendees.map((item, index) => (
            <AttendeeListItem key={`${index}`} event={event} attendee={item} navigation={navigation} />
          ))}
        </View>
      </View>
    );
  } else {
    return null;
  }
};

export default AttendeeOrderSection;
