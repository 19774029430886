import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import CheckinLogItem from './CheckinLogItem';
import { LocaleContext } from '../../contexts/LocaleContext';

dayjs.extend(customParseFormat);

const CheckinLog = ({ event, attendee }) => {
  const { t } = React.useContext(LocaleContext);

  if (!event || !attendee) {
    return null;
  }

  if (attendee.checkin_actions && attendee.checkin_actions.length > 0) {
    const actions = attendee.checkin_actions.sort((a, b) => dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1 : -1);

    return (
      <View>
        <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 18}}>
          <Text style={{ fontSize: 20, fontWeight: 'bold', color: '#161616' }}>{t('attendee.checkin_history')}</Text>
          <Text style={{ fontSize: 12, fontWeight: 'bold', color: '#7f7f7f' }}>{t('attendee.checkin_history_subtitle')}</Text>
        </View>
        <View style={{ marginTop: 10, paddingLeft: 20 }}>
          {actions.map((checkin, index) => (
            <CheckinLogItem key={`${index}`} event={event} attendee={attendee} item={checkin} />
          ))}
        </View>
      </View>
    );
  } else {
    return null;
  }
};

export default CheckinLog;
