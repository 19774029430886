import React from 'react';
import { StyleSheet } from 'react-native';
import { Button } from 'react-native-elements';
import { theme } from '../../../app.json';

const styles = StyleSheet.create({
  containerStyle: {
    width: '100%',
    marginTop: 23,
  },
  buttonStyle: {
    backgroundColor: theme.primaryColor,
    paddingHorizontal: 14,
    paddingVertical: 16,
    borderRadius: 4,
  },
  titleStyle: {
    color: theme.primaryFontColor,
  },
});

const PrimaryButton = (props) => {
  return (
    <Button
      {...props}
      containerStyle={[styles.containerStyle, ...(props.containerStyle ? [props.containerStyle] : [])]}
      buttonStyle={[styles.buttonStyle, ...(props.buttonStyle ? [props.buttonStyle] : [])]}
      titleStyle={[styles.titleStyle, ...(props.titleStyle ? [props.titleStyle] : [])]}
    />
  )
};

export default PrimaryButton;
