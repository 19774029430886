import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, ListItem } from 'react-native-elements';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { LocaleContext } from '../../contexts/LocaleContext';

dayjs.extend(customParseFormat);

const styles = StyleSheet.create({
});

const CheckinLogItem = ({ event, attendee, item }) => {
  const { t } = React.useContext(LocaleContext);

  return (
    <ListItem bottomDivider>
      <ListItem.Content>
        <ListItem.Title>{item.action === 'cancel' ? t('attendee.checkedin_cancelled_on') : t('attendee.checked_in_on')} {dayjs(item.created_at).format('MMMM D, YYYY h:mm A')}</ListItem.Title>
        {item.user ? (
          <ListItem.Subtitle style={{marginTop:5}}>{`${t('common.by')} ${item.user.email}`}</ListItem.Subtitle>
        ) : null}
      </ListItem.Content>
    </ListItem>
  );
};

export default CheckinLogItem;
