import React from 'react';
import { SafeAreaView, View, Image, Pressable, ScrollView, Linking, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Text, Button, Input } from 'react-native-elements';
import { AuthContext } from '../contexts/AuthContext';
import Banner from '../components/shared/Banner';
import EveyLogo from '../../assets/icon.png';
import { theme } from '../../app.json';
import TextInput from '../components/shared/TextInput';
import PrimaryButton from '../components/shared/PrimaryButton';
import { LocaleContext } from '../contexts/LocaleContext';

const LoginScreen = ({ navigation }) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const passwordInputRef = React.useRef();

  const { signIn, attemptingAttendee } = React.useContext(AuthContext);
  const { t } = React.useContext(LocaleContext);

  React.useEffect(() => {
    if (attemptingAttendee) {
      setErrorMessage(t('login.ticket_no_access'));
    }
  }, []);

  const login = async () => {
    try {
      setLoading(true);
      setErrorMessage('');
      await signIn({ email, password });
    } catch (error) {
      if (error.name === 'AuthenticationRequiredError') {
        setErrorMessage(t('login.invalid_email_password'));
      } else {
        console.log(`Login error: ${error}`);
        setErrorMessage(t('login.unknown_error'));
      }
    }
    setLoading(false);
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
      <KeyboardAwareScrollView contentContainerStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
        <View style={{ paddingLeft: 28, paddingRight: 28, width: '100%' }}>
          <Text style={{ marginBottom: 20, marginTop: 36, textAlign: 'center', fontSize: 24, lineHeight: 44, fontWeight: '500' }}>Check-In Login</Text>
          <TextInput
            placeholder='email@address.com'
            label={t('login.email')}
            onChangeText={setEmail}
            value={email}
            autoCompleteType='email'
            keyboardType='email-address'
            returnKeyType='next'
            autoCapitalize='none'
            onSubmitEditing={() => passwordInputRef.current.focus()}
          />
          <TextInput
            placeholder="*********"
            label={t('login.password')}
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            autoCompleteType='password'
            secureTextEntry={true}
            textContentType='password'
            returnKeyType='go'
            autoCapitalize='none'
            inputRef={passwordInputRef}
            onSubmitEditing={() => login()}
            containerStyle={{ marginTop: 28 }}
          />
          <PrimaryButton
            title={t('login.signin')}
            onPress={() => login()}
            loading={loading}
          />
          {errorMessage ? (
            <Text style={{ color: 'red', marginTop: 20 }}>{errorMessage}</Text>
          ) : (
            null
          )}
          <View style={{ marginTop: 50 }}>
            <Button
              type='clear'
              title={t('login.forgot_password')}
              onPress={() => {
                navigation.navigate('ForgotPassword');
              }}
              titleStyle={{ color: theme.primaryColor }}
            />
          </View>
        </View>
        <View style={{ width: '100%', paddingLeft: 22, paddingRight: 22 }}>
          <View style={{borderWidth: 1, borderStyle: 'solid', borderColor: '#000000', width: '100%', marginTop: 40, marginBottom: 29, borderTopWidth: 0}}></View>
        </View>
        <View style={{ paddingHorizontal: 35 }}>
          <Text style={{ fontStyle: 'italic', fontSize: 14, lineHeight: 20, color: '#545454', }}>{t('login.note')}</Text>
          <Text style={{ marginTop: 20, color: '#0289df' }} onPress={() => Linking.openURL('https://evey-events.zendesk.com/hc/en-us/articles/12216771390235-Getting-Ready-For-Your-Event')}>{t('common.learn_more')}</Text>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

export default LoginScreen;
