import React from 'react';
import { SafeAreaView, View, FlatList, Platform, Modal as NativeModal, Pressable } from 'react-native';
import { SearchBar, Button, Overlay, Text, Badge } from 'react-native-elements';
import { DataContext } from '../contexts/DataContext';
import DropDownPicker from 'react-native-dropdown-picker';
import EventInfoOverlay from '../components/attendees/EventInfoOverlay';
import ScheduleCalendar from '../components/attendees/ScheduleCalendar';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';
import * as WebModal from 'modal-react-native-web';
import { toastError } from '../helpers/toast';
import HeaderBackButton from '../components/shared/HeaderBackButton';
import ActivityIndicator from '../components/shared/ActivityIndicator';
import AttendeeListItem from '../components/attendee/AttendeeListItem';
import Modal from '../components/shared/Modal';
import { SettingsContext } from '../contexts/SettingsContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { ScannerContext } from '../contexts/ScannerContext';

const FilterItem = ({ label, options, onChange, selectedValue, zIndex }) => {
  return (
    <View style={Platform.OS === 'android' ? {marginBottom:20} : {marginBottom:20, zIndex:zIndex}}>
      <Text style={{marginBottom:8, fontSize: 14}}>{label}</Text>
      <DropDownPicker
        items={options}
        defaultValue={selectedValue}
        onChangeItem={(item) => onChange(item.value)}
        containerStyle={{height: 40}}
        style={{backgroundColor: '#fafafa'}}
        itemStyle={{
          justifyContent: 'flex-start',
          zIndex: zIndex,
        }}
        zIndex={zIndex}
        zIndexInverse={10000}
      />
    </View>
  );
};

const AttendeesScreen = ({ navigation, route }) => {
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const [filtersModalOpen, setFiltersModalOpen] = React.useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = React.useState(false);
  const [refreshing, setRefreshing] = React.useState(false);
  const [localSearch, setLocalSearch] = React.useState('');

  const { fetchEvent, findEvent, fetchAttendees, attendees, loading, hasMoreAttendees, fetchAttendeesNextPage, loadingMore, attendeesFilters, setAttendeesFilter, attendeesFiltersDirty, resetAttendeeFilters } = React.useContext(DataContext);
  const { defaultSort } = React.useContext(SettingsContext);
  const { t } = React.useContext(LocaleContext);
  const { startKeyboardMonitor, stopKeyboardMonitor } = React.useContext(ScannerContext);

  let { eventId, skipNoAuthSignOut } = route.params;

  const event = findEvent(eventId);

  const filters = attendeesFilters;
  const setFilterValue = (name, value) => setAttendeesFilter(name, value);

  const fetchErrorToast = () => {
    toastError({
      title: t('messages.fetch_tickets_failed_title'),
      message: t('messages.fetch_tickets_failed'),
    });
  };

  const refetchAttendees = async () => {
    try {
      await fetchAttendees({
        useCache: false,
        eventId: event.id,
        search: filters.search,
        sort: filters.sort,
        checkinStatus: filters.checkinStatus,
        ticketStatus: filters.ticketStatus,
        ticketType: filters.ticketType,
        scheduledOn: filters.scheduledOn || '',
        skipNoAuthSignOut,
      });
    } catch (error) {
      fetchErrorToast();
    }
  }

  const fetchMoreAttendees = async () => {
    if (hasMoreAttendees) {
      try {
        await fetchAttendeesNextPage({
          eventId: event.id,
          search: filters.search,
          sort: filters.sort,
          checkinStatus: filters.checkinStatus,
          ticketStatus: filters.ticketStatus,
          ticketType: filters.ticketType,
          scheduledOn: filters.scheduledOn || '',
        });
      } catch (error) {
        fetchErrorToast();
      }
    }
  }

  React.useEffect(() => {
    if (event) {
      refetchAttendees()
    }
  }, [filters.scheduledOn, filters.search]);

  React.useEffect(() => {
    (async () => {
      try {
        await fetchEvent({ eventId, skipNoAuthSignOut });
      } catch (error) {
        console.log(`Error fetching event: ${error}`);
        fetchErrorToast();
      }
    })();
    (async () => {
      try {
        await fetchAttendees({
          eventId: eventId,
          search: filters.search,
          sort: filters.sort,
          checkinStatus: filters.checkinStatus,
          ticketStatus: filters.ticketStatus,
          ticketType: filters.ticketType,
          scheduledOn: filters.scheduledOn || '',
          skipNoAuthSignOut,
        });
      } catch (error) {
        console.log(`Error: ${error}`);
        fetchErrorToast();
      }
    })();
  }, []);

  React.useLayoutEffect(() => {
    // { search: '', sort: 'ticket', checkinStatus: 'all', ticketStatus: 'active', ticketType: 'all' }
    const filtersDirty = filters.sort != (defaultSort || 'ticket') || filters.checkinStatus != 'all' || filters.ticketStatus != 'active' || filters.ticketType != 'all';
    navigation.setOptions({
      headerRight: () => (
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <Button
            type='plain'
            icon={<MaterialIcons name='info-outline' size={30} color='white' />}
            onPress={() => setInfoModalOpen(true)}
          />
          {event && event.date_type === 'recurring' ? (
            <View>
              <Button
                type='plain'
                icon={<FontAwesome name='calendar' size={30} color='white' />}
                onPress={() => setScheduleModalOpen(true)}
              />
              {filters.scheduledOn ?
                <Badge status='success' containerStyle={{ position: 'absolute', top: 8, right: 4 }} />
                : null
              }
            </View>
          ) : ( null )
          }
          <View>
            <Button
              type='plain'
              icon={<MaterialIcons name='filter-list' size={30} color='white' />}
              onPress={() => setFiltersModalOpen(true)}
            />
            {filtersDirty ?
              <Badge status='success' containerStyle={{ position: 'absolute', top: 10, right: 10 }} />
              : null
            }
          </View>
        </View>
      ),
      // headerTitleAlign: 'center',
      // headerLeft: () => (
      //   <HeaderBackButton
      //     onPress={()=>{
      //       navigation.reset({
      //         index: 0,
      //         routes: [{ name: 'Events' }]
      //       });
      //     }}
      //   />
      // ),
    });
  }, [navigation, filters, event]);

  const renderFooter = () => {
    if (loading || !event) {
      return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 100 }}>
          <ActivityIndicator />
        </View>
      );
    } else {
      if (!hasMoreAttendees) return null;
      if (hasMoreAttendees) {
        return (
          <View>
            <Button type='clear' title='Load more' loading={loadingMore} onPress={() => fetchMoreAttendees()} />
          </View>
        );
      } else {
        return null;
      }
    }
  };

  const header = (
    <View>
      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
        <View style={{flex: 1}}>
          <SearchBar
            placeholder={`${t('common.search')}...`}
            onChangeText={(v) => setLocalSearch(v)}
            value={localSearch}
            containerStyle={{ backgroundColor: '#fff', borderBottomColor: 'rgba(0, 0, 0, 0.12)', borderTopColor: 'rgba(0, 0, 0, 0.12)' }}
            inputContainerStyle={{ backgroundColor: '#fff' }}
            inputStyle={{paddingRight:20}}
            onSubmitEditing={() => setFilterValue('search', localSearch)}
            showLoading={loading && attendees && attendees.length > 0}
            loadingProps={Platform.OS === 'android' ? {
              animating: true,
              color: "black",
            } : undefined}
            onClear={() => setFilterValue('search', '')}
            onFocus={() => stopKeyboardMonitor()}
            onBlur={() => startKeyboardMonitor()}
          />
        </View>
        {/*<View style={{marginRight: 5, marginLeft: 5, position: 'absolute', right: 10}}>
          <Button
            title=""
            icon={loading ? (
              <ActivityIndicator size="small" color="white" />
            ) : (
              <MaterialIcons name='refresh' size={15} color='white' />
            )}
            type='solid'
            onPress={() => refetchAttendees()}
          />
        </View>*/}
      </View>
    </View>
  );

  return (
    <SafeAreaView style={{flex:1, backgroundColor: '#fff'}}>
      <FlatList
        data={attendees}
        renderItem={({ item, index }) => <AttendeeListItem key={`${index}`} event={event} attendee={item} navigation={navigation} />}
        keyExtractor={(item) => `${item.id}`}
        refreshing={refreshing}
        onEndReachedThreshold={0.5}
        onEndReached={({ distanceFromEnd }) => fetchMoreAttendees()}
        ListFooterComponent={renderFooter()}
        onRefresh={async () => {
          setRefreshing(true);
          await refetchAttendees();
          setRefreshing(false);
        }}
        ListHeaderComponent={header}
      />
      {infoModalOpen &&
        <EventInfoOverlay eventId={event.id} onClose={() => setInfoModalOpen(false)} />
      }

      {filtersModalOpen &&
        <Modal
          onClose={() => setFiltersModalOpen(false)}
          heading={t('filters.filter_sort')}
          primaryAction={{
            content: t('filters.update'),
            onPress: () => {
              refetchAttendees();
              setFiltersModalOpen(false);
            },
          }}
          secondaryAction={{
            content: t('filters.clear'),
            onPress: () => {
              resetAttendeeFilters();
              // setTimeout(() => refetchAttendees(), 100);
              // setFiltersModalOpen(false);
            },
          }}
        >
          <View style={{paddingTop: 18, paddingLeft: 9, paddingRight: 9, width: '100%'}}>
            <FilterItem
              label={t('filters.sort_by')}
              options={[
                { label: t('filters.sort_options.ticket_number_asc'), value: 'ticket' },
                { label: t('filters.sort_options.ticket_number_desc'), value: '-ticket' },
                { label: t('filters.sort_options.date_asc'), value: 'date' },
                { label: t('filters.sort_options.date_desc'), value: '-date' },
                { label: t('filters.sort_options.name_asc'), value: 'name' },
                { label: t('filters.sort_options.name_desc'), value: '-name' },
                { label: t('filters.sort_options.checkin_asc'), value: 'checkin_date' },
                { label: t('filters.sort_options.checkin_desc'), value: '-checkin_date' },
              ]}
              onChange={(v) => setFilterValue('sort', v)}
              selectedValue={filters.sort}
              zIndex={5000}
            />
            <FilterItem
              label={t('filters.checkin_status')}
              options={[
                { label: t('filters.all'), value: 'all' },
                { label: t('filters.checkin_status_options.notcheckedin'), value: 'notcheckedin' },
                { label: t('filters.checkin_status_options.checkedin'), value: 'checkedin' },
              ]}
              onChange={(v) => setFilterValue('checkinStatus', v)}
              selectedValue={filters.checkinStatus}
              zIndex={4000}
            />
            <FilterItem
              label={t('filters.ticket_status')}
              options={[
                { label: t('filters.ticket_status_options.active'), value: 'active' },
                { label: t('filters.all'), value: 'all' },
                { label: t('filters.ticket_status_options.cancelled'), value: 'cancelled' },
              ]}
              onChange={(v) => setFilterValue('ticketStatus', v)}
              selectedValue={filters.ticketStatus}
              zIndex={3000}
            />
            <FilterItem
              label={t('filters.ticket_type')}
              options={[
                { label: t('filters.all'), value: 'all' },
                ...(event.ticket_types.map((t) => ({
                  label: t.title,
                  value: `${t.id}`,
                }))),
              ]}
              onChange={(v) => setFilterValue('ticketType', v)}
              selectedValue={filters.ticketType}
              zIndex={2000}
            />
          </View>
        </Modal>
      }

      {scheduleModalOpen &&
        <Overlay
          ModalComponent={Platform.OS === 'web' ? WebModal : NativeModal}
          isVisible={true}
          onBackdropPress={() => setScheduleModalOpen(false)}
          fullScreen={false}
        >
          <ScheduleCalendar
            event={event}
            selected={filters.scheduledOn}
            setSelected={(d) => {
              setFilterValue('scheduledOn', d);
              setScheduleModalOpen(false);
            }}
          />
        </Overlay>
      }
    </SafeAreaView>
  );
}

export default AttendeesScreen;
