import React from 'react';
import { StyleSheet } from 'react-native';
import { Input } from 'react-native-elements';

const styles = StyleSheet.create({
  label: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: 20,
    color: '#000000',
    marginBottom: 8,
  },
  input: {
    backgroundColor: '#EEEEEE',
    borderRadius: 4,
    fontSize: 16,
    lineHeight: 20,
    // color: '#757575',
    color: '#000000',
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  inputContainer: {
    borderWidth: 0,
  },
  container: {
    paddingHorizontal: 0,
  },
});

const TextInput = (props) => {
  return (
    <Input
      {...props}
      labelStyle={styles.label}
      inputStyle={styles.input}
      inputContainerStyle={styles.inputContainer}
      containerStyle={styles.container}
      {...(props.inputRef ? { ref: props.inputRef } : {})}
    />
  )
};

export default TextInput;
