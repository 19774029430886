import React from 'react';
import { View, Modal as NativeModal, Platform, Dimensions, StyleSheet } from 'react-native';
import { Text, Overlay, Button } from 'react-native-elements';
import * as WebModal from 'modal-react-native-web';

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: '#FFFFFF',
    borderRadius: 9,
    padding: 11,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: Math.min(360, 0.96*windowWidth)
  },
  heading: {
    width: '100%',
    fontSize: 20,
    fontWeight: '500',
    lineHeight: 36,
  },
  subheading: {
    fontSize: 14,
    lineHeight: 16,
    marginTop: 8,
    width: '100%',
  },
  primaryAction: {
    height: 48,
    width: '100%',
    marginBottom: 0,
    marginTop: 24,
    paddingLeft: 7,
    paddingRight: 7,
    zIndex:-1,
  },
  secondaryAction: {
    height: 48,
    width: '100%',
    marginBottom: 0,
    marginTop: 11,
    paddingLeft: 7,
    paddingRight: 7,
    zIndex:-1,
  },
  content: {
    width: '100%',
    paddingTop: 8,
  },
  headingContainer: {
    width: '100%',
    paddingLeft: 7,
    paddingRight: 7,
  },
});

const Modal = ({ onClose, children, heading, subheading, primaryAction, secondaryAction }) => {
  return (
    <Overlay
      ModalComponent={Platform.OS === 'web' ? WebModal : NativeModal}
      isVisible={true}
      onBackdropPress={() => onClose()}
      fullScreen={false}
      overlayStyle={styles.overlay}
    >
      <View style={styles.container}>
        {heading ? (
          <View style={styles.headingContainer}>
            {heading ? <Text style={styles.heading}>{heading}</Text> : null}
            {subheading ? <Text style={styles.subheading}>{subheading}</Text> : null}
          </View>
        ) : null}
        <View style={{borderWidth: 1, borderStyle: 'solid', borderColor: '#000000', width: '100%', marginTop: 22, borderTopWidth: 0}}></View>
        <View style={styles.content}>
          {children}
        </View>
        {primaryAction ? (
          <Button
            title={primaryAction.content}
            onPress={primaryAction.onPress}
            containerStyle={styles.primaryAction}
            buttonStyle={{backgroundColor: '#000000'}}
          />
        ) : null}
        {secondaryAction ? (
          <Button
            title={secondaryAction.content}
            onPress={secondaryAction.onPress}
            containerStyle={styles.secondaryAction}
            buttonStyle={{backgroundColor: '#EEEEEE'}}
            titleStyle={{ color: '#000000' }}
          />
        ) : null}
      </View>
    </Overlay>
  );
};

export default Modal;
