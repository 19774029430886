import Toast from 'react-native-toast-message';

const DEFAULT_POSITION = 'top';
const DEFAULT_DURATION = 4000;
const DEFAULT_TOP_OFFSET = 100;

export const toastNotify = ({ title, message, position, duration, onShow, onHide, onPress }) => {
  Toast.show({
    text1: title,
    text2: message,
    type: 'success',
    position: position || DEFAULT_POSITION,
    visibilityTime: duration || DEFAULT_DURATION,
    topOffset: DEFAULT_TOP_OFFSET,
    onShow,
    onHide,
    onPress,
  });
};

export const toastError = ({ title, message, position, duration, onShow, onHide, onPress }) => {
  Toast.show({
    text1: title,
    text2: message,
    type: 'error',
    position: position || DEFAULT_POSITION,
    visibilityTime: duration || DEFAULT_DURATION,
    topOffset: DEFAULT_TOP_OFFSET,
    onShow,
    onHide,
    onPress,
    props: {
      style: {
        color: '#7f7f7f',
      },
    },
  });
};
