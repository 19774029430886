import React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { Button, Text } from 'react-native-elements';

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingRight: 10
  },
  banner: {
    backgroundColor: '#f1f4f5',
    padding: 20,
    borderRadius: 10,
    borderColor: '#acbcab',
    borderStyle: 'solid',
    borderWidth: 1,
  },
});

const Banner = ({ title, children, status, style, containerStyle }) => {

  let backgroundColor = '#f1f4f5';
  let borderColor: '#acbcab';
  if (status === 'critical') {
    backgroundColor = '#fff0f1';
    borderColor = '#d7a3a3';
  } else if (status === 'info') {
    backgroundColor = '#E6F7FB';
    borderColor = '#87BBC2';
  } else if (status === 'warning') {
    backgroundColor = '#FEF2E5';
    borderColor = '#D9AA65';
  }

  return (
    <View style={[containerStyle, styles.container]}>
      <View style={[style, styles.banner, { backgroundColor: backgroundColor, borderColor: borderColor, }]}>
        {title ? <Text style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>{title}</Text> : null}
        {children}
      </View>
    </View>
  );
};

export default Banner;
