import React from 'react';
import { loginRequest, logout } from '../helpers/api';
import jwt_decode from "jwt-decode";
import { storeData, fetchData } from '../helpers/storage';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [attemptingAttendee, setAttemptingAttendee] = React.useState(null);

  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      let decodedToken;
      switch (action.type) {
        case 'RESTORE_TOKEN':
          if (action.token) {
            decodedToken = jwt_decode(action.token);
            // console.log(`RESTORE_TOKEN decodedToken: ${JSON.stringify(decodedToken)}`);
          }

          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
            userEmail: decodedToken?.email,
          };
        case 'SIGN_IN':
          decodedToken = jwt_decode(action.token);
          // console.log(`SIGN_IN decodedToken: ${JSON.stringify(decodedToken)}`);

          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
            userEmail: decodedToken?.email,
          };
        case 'SIGN_OUT':
          logout();
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            userEmail: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
      userEmail: null,
    }
  );

  React.useEffect(() => {
    (async () => {
      let userToken;

      try {
        userToken = await fetchData('userToken');
        // console.log(`Previous user token exists: ${userToken}`);
        const decodedToken = jwt_decode(userToken);
        // console.log(`Previous token decodedToken: ${JSON.stringify(decodedToken)}`);
        const tokenHeader = jwt_decode(userToken, { header: true });
        // console.log(`Previous token header: ${JSON.stringify(tokenHeader)}`)
      } catch (e) {
      }
      dispatch({ type: 'RESTORE_TOKEN', token: userToken });
    })();
  }, []);

  const authContext = {
    userToken: state.userToken,
    isLoggedIn: state.userToken != null,
    loading: state.isLoading,
    userEmail: state.userEmail,
    signIn: async ({ email, password }) => {
      const response = await loginRequest({ email, password });
      console.log(`Login successful for ${response.email}: ${response.access_token}`);

      const decodedToken = jwt_decode(response.access_token);
      // console.log(`decodedToken: ${JSON.stringify(decodedToken)}`);

      dispatch({ type: 'SIGN_IN', token: response.access_token });
    },
    signOut: () => dispatch({ type: 'SIGN_OUT' }),
    attemptingAttendee,
    setAttemptingAttendee,
  };

  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
};
