import React from 'react';
import { SafeAreaView, View, SectionList, TouchableOpacity, Alert, Platform, Image, Switch, ScrollView } from 'react-native';
import { ListItem, Text, Button, Slider } from 'react-native-elements';
import { AuthContext } from '../contexts/AuthContext';
import { MaterialIcons, MaterialCommunityIcons, AntDesign } from '@expo/vector-icons';
import { createStackNavigator } from '@react-navigation/stack';
import { DataContext } from '../contexts/DataContext';
import socketMobileIcon from '../../assets/socket-mobile.png';
import { useFocusEffect } from '@react-navigation/native';
import { ScannerContext } from  '../contexts/ScannerContext';
import { theme } from '../../app.json';
import { SettingsContext } from '../contexts/SettingsContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { openUrl } from '../helpers/linking';
import { asTimezoneArr } from '../helpers/time';
import Constants from 'expo-constants';
import { commit as commitVersion } from '../../version.json';
import DropDownPicker from 'react-native-dropdown-picker';

const Stack = createStackNavigator();

const Picker = ({ items, value, onChange }) => {
  if (Platform.OS === 'android') {
    return (
      <DropDownPicker
      items={items}
      defaultValue={value}
      onChangeItem={(item) => onChange(item.value)}
      style={{backgroundColor: '#fafafa'}}
      containerStyle={{height: 50, width: 200}}
      itemStyle={{
        justifyContent: 'flex-start',
        zIndex: 5000,
      }}
      zIndex={5000}
      zIndexInverse={10000}
    />
    );
  } else {
    return (
      <DropDownPicker
        items={items}
        defaultValue={value}
        onChangeItem={(item) => onChange(item.value)}
        style={{backgroundColor: '#fafafa'}}
        containerStyle={{height: 55, width: 200}}
        itemStyle={{
          justifyContent: 'flex-start',
          zIndex: 5000,
        }}
        zIndex={5000}
        zIndexInverse={10000}
      />
    );
  }
};

const SettingsScreen = ({ navigation }) => {
  const { userEmail, signOut } = React.useContext(AuthContext);
  const { clearData } = React.useContext(DataContext);
  const { scanners } = React.useContext(ScannerContext);
  const { autoCheckin, scanningDelay, defaultSort, timezone, updateSetting } = React.useContext(SettingsContext);
  const { availableLocales, defaultLanguage, language, setLanguage, t } = React.useContext(LocaleContext);
  const inheritTimezoneFromLocal = userEmail && localStorage.getItem(`EveyCheckin:Settings:${userEmail}:autoTimezone`); 
  const timezoneFromLocal = userEmail && localStorage.getItem(`EveyCheckin:Settings:${userEmail}:timezone`);
  const [inheritTimezone, setInheritTimezone] = React.useState(inheritTimezoneFromLocal && inheritTimezoneFromLocal == 'false' ? false : true);

  const logout = () => {
    if (Platform.OS == 'web') {
      const r = confirm(t('settings.logout_confirm'))
      if (r == true) {
        signOut();
        clearData();
      }
    } else {
      Alert.alert(
        t('settings.logout'),
        t('settings.logout_confirm'),
        [
          {
            text: t('common.cancel'),
            onPress: () => {},
            style: "cancel"
          },
          {
            text: t('common.yes'),
            onPress: () => {
              signOut();
              clearData();
            },
          }
        ],
        { cancelable: false }
      );
    }
  };

  const settingsData = [
    {
      key: 'account',
      title: `${t('settings.account')}: ${userEmail}`,
      data: [
        {
          type: 'action',
          label: t('settings.logout'),
          onPress: () => logout(),
        },
        {
          label: t('common.change_password'),
          type: 'action',
          onPress: () => {
            navigation.navigate('ForgotPassword');
          }
        }
      ],
    },
    {
      key: 'scanners',
      title: t('settings.scanners'),
      data: [
        ...scanners.map((deviceName) => (
          {
            label: deviceName,
            type: 'scanner',
            left: <Image source={socketMobileIcon} style={{ width: 30, height: 30 }} />,
          }
        )),
        {
          label: t('settings.scanners_learn'),
          type: 'learn-more',
          onPress: () => openUrl('https://evey-events.zendesk.com/hc/en-us/articles/12216876591643-Connecting-the-Socket-Mobile-S740-or-CHS-7Qi-2D-barcode-QR-code-scanner'),
          right: (
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
              <MaterialIcons name='info-outline' size={25} color='black' />
            </View>
          ),
        },
      ],
    },
    {
      key: 'prefs',
      title: t('settings.preferences'),
      data: [
        {
          type: 'options',
          label: t('settings.language'),
          zIndex: 101,
          right: (
            <Picker
              items={availableLocales.map((item) => ({ label: t(`settings.language_${item.value}`), value: item.value }))}
              value={availableLocales.find((item) => item.value == language) ? language : 'en'}
              onChange={(v) => setLanguage(v)}
            />
          ),
        },
        {
          type: 'options',
          label: t('settings.default_ticket_order'),
          helpText: t('settings.default_ticket_order_help'),
          zIndex: 100,
          right: (
            <Picker
              items={[
                { label: t('filters.sort_options.ticket_number_asc'), value: 'ticket' },
                { label: t('filters.sort_options.ticket_number_desc'), value: '-ticket' },
                { label: t('filters.sort_options.date_asc'), value: 'date' },
                { label: t('filters.sort_options.date_desc'), value: '-date' },
                { label: t('filters.sort_options.name_asc'), value: 'name' },
                { label: t('filters.sort_options.name_desc'), value: '-name' },
                { label: t('filters.sort_options.checkin_asc'), value: 'checkin_date' },
                { label: t('filters.sort_options.checkin_desc'), value: '-checkin_date' }
              ]}
              value={defaultSort || 'ticket'}
              onChange={(v) => updateSetting('defaultSort', v)}
            />
          ),
        },
        {
          type: 'switch',
          label: t('settings.auto_checkin'),
          helpText: t('settings.auto_checkin_help'),
          right: (
            <Switch
              trackColor={{ false: '#767577', true: theme.tintColor }}
              thumbColor={'#f4f3f4'}
              ios_backgroundColor="#3e3e3e"
              onValueChange={(v) => updateSetting('autoCheckin', v) }
              value={autoCheckin}
            />
          ),
        },
        {
          type: 'switch',
          label: t('settings.timezone_auto'),
          helpText: t('settings.timezone_help'),
          zIndex: 101,
          right: (
            <Switch
              trackColor={{ false: '#767577', true: theme.tintColor }}
              thumbColor={'#f4f3f4'}
              ios_backgroundColor="#3e3e3e"
              onValueChange={(v) => {
                setInheritTimezone(v) 
                updateSetting('autoTimezone', v)
                if(v) localStorage.removeItem(`EveyCheckin:Settings:${userEmail}:timezone`)
                else updateSetting('timezone', 'Etc/UTC')
              }}
              value={inheritTimezone}
            /> 
          ),
        }, 
        {
          type: 'options',
          hide: inheritTimezone,
          label: t('settings.timezone'),
          zIndex: 101,
          right: (
            <Picker
              items={asTimezoneArr}
              value={timezoneFromLocal ? timezoneFromLocal : 'Etc/UTC'}
              onChange={(v) => updateSetting('timezone', v)}
          />
          ),
        },        
        {
          type: 'number',
          label: t('settings.external_scanning_delay'),
          helpText: t('settings.external_scanning_delay_help'),
          below: (
            <View style={{width:'100%', marginTop: 20}}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Button
                  icon=<AntDesign name="minus" size={20} color="#3c3c3c" />
                  type='outline'
                  onPress={() => updateSetting('scanningDelay', Math.max(scanningDelay - 1000, 0))}
                  buttonStyle={{ borderColor: '#3c3c3c' }}
                />
                <Slider
                  style={{width: 200, marginLeft: 10, marginRight: 10}}
                  value={(scanningDelay || 0) / 1000}
                  onValueChange={(v) => updateSetting('scanningDelay', parseInt(v)*1000)}
                  thumbStyle={{width:30, height:30, backgroundColor: '#3e3e3e'}}
                  minimumValue={1}
                  maximumValue={10}
                  step={1}
                  animateTransitions={true}
                />
                <Button
                  icon=<AntDesign name="plus" size={20} color="#3c3c3c" />
                  type='outline'
                  onPress={() => updateSetting('scanningDelay', Math.min(scanningDelay + 1000, 10000))}
                  buttonStyle={{ borderColor: '#3c3c3c' }}
                />
              </View>
            </View>
          ),
          right: (
            <Text>{(scanningDelay || 0) / 1000} {t('settings.seconds')}</Text>
          )
        },
      ],
    },
    {
      key: 'support',
      title: t('settings.support'),
      data: [
        {
          label: t('settings.visit_help'),
          type: 'link',
          onPress: () => openUrl('https://evey-events.zendesk.com/hc'),
          right: (
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
              <MaterialIcons name='help-outline' size={25} color='black' />
            </View>
          ),
        },
        {
          label: t('settings.contact_us'),
          type: 'link',
          onPress: () => openUrl('mailto:help@eveyevents.com'),
          right: (
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
              <MaterialCommunityIcons name='email-outline' size={25} color='black' />
            </View>
          ),
          helpText: 'help@eveyevents.com',
        }
      ],
    },
    {
      key: 'about',
      title: t('settings.about'),
      data: [
        {
          label: t('settings.version'),
          type: 'text',
          helpText: Platform.OS == 'web' ? `Web ${commitVersion && commitVersion.slice(0, 7)}` : `${Constants.nativeAppVersion} build ${Constants.nativeBuildVersion} (${commitVersion && commitVersion.slice(0, 7)})`
        }
      ],
    }
  ];

  const renderItem = ({ item }) => {
    return (
        <>      
          { !item?.hide && (
            <ListItem
            bottomDivider
            onPress={item.onPress}
          >
            <View>
              {item.left}
            </View>
            <ListItem.Content>
              <ListItem.Title>{item.label}</ListItem.Title>
              {item.helpText &&
                <ListItem.Subtitle style={{ fontSize: 12, marginTop: 5 }}>{item.helpText}</ListItem.Subtitle>
              }
              {item.below}
            </ListItem.Content>
            {item.type === 'action' &&
              <ListItem.Chevron />
            }
            {item.right}
            </ListItem>
          )}
        </>
    );
  };

  // const SettingsContent = () => (
  //   <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
  //     <SectionList
  //       sections={settingsData}
  //       keyExtractor={(item, index) => `${item.type}${index}`}
  //       renderItem={({ item }) => renderItem({ item })}
  //       renderSectionHeader={({ section: { title } }) => (
  //         <Text style={{ fontSize: 14, backgroundColor: '#fff', marginLeft: 10, marginTop: 10, fontWeight: 'bold' }}>{title}</Text>
  //       )}
  //     />
  //   </SafeAreaView>
  // );

  const SettingsContent = () => (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
      <ScrollView>
        {settingsData.map((section, index) => (
          <View key={index}>
            <Text style={{ fontWeight: 'bold', fontSize: 14, color: '#242424', marginLeft: 10, marginTop: 10 }}>{section.title}</Text>
            {section.data.map((item, index) => (
              <View style={item.zIndex ? { zIndex: item.zIndex } : {}} key={`${item.type}${index}`}>{renderItem({ item })}</View>
            ))}
          </View>
        ))}
      </ScrollView>
    </SafeAreaView>
  );

  return (
    <Stack.Navigator
      initialRouteName="Settings"
      screenOptions={{
        title: t('navigation.settings'),
        headerStyle: {
          backgroundColor: theme.primaryColor,
        },
        headerTintColor: theme.primaryFontColor,
        headerTitleStyle: {
          fontWeight: 'bold',
        },
      }}
    >
      <Stack.Screen name="Settings" component={SettingsContent} />
    </Stack.Navigator>
  );
}

export default SettingsScreen;
