import React from 'react';
import { View, Platform } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
let Camera;
try {
   Camera = require('expo-camera').Camera;
} catch (err) {
  console.log(`Unable to load camera module: ${err}`);
}
import { MaterialIcons } from '@expo/vector-icons';

export const requestCameraPermissionsAsync = async () => Platform.OS === 'android' ? (await Camera.requestCameraPermissionsAsync()) : (await BarCodeScanner.requestPermissionsAsync());

export const CAMERA_TYPE_BACK = Platform.OS === 'android' ? Camera.Constants.Type.back : BarCodeScanner.Constants.Type.back;
export const CAMERA_TYPE_FRONT = Platform.OS === 'android' ? Camera.Constants.Type.front : BarCodeScanner.Constants.Type.front;

export const CameraScanner = ({ style, type, onBarCodeScanned, children }) => {
  if (Platform.OS === 'ios') {
    return (
      <BarCodeScanner
        onBarCodeScanned={onBarCodeScanned}
        style={style}
        barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
        type={type}
      >
        {children}
      </BarCodeScanner>
    )
  } else {
    return (
      <Camera
        onBarCodeScanned={onBarCodeScanned}
        style={style}
        type={type}
        barCodeScannerSettings={{
          barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
        }}
      >
        {children}
      </Camera>
    );
  }
};
