import React from 'react';
import { StatusBar } from 'expo-status-bar';
import { ThemeProvider } from 'react-native-elements';
import AppContainer from './src/screens/AppContainer';
import { AuthProvider } from './src/contexts/AuthContext';
import { LocaleProvider } from './src/contexts/LocaleContext';
import ErrorBoundary from './src/components/shared/ErrorBoundary';
import * as Updates from 'expo-updates';
import * as SplashScreen from 'expo-splash-screen';
import { patchFlatListProps } from 'react-native-web-refresh-control';

patchFlatListProps();

const App = () => {
  React.useEffect(() => {
    (async () => {
      try {
        await SplashScreen.preventAutoHideAsync();
        console.log(`Checking for remote app updates`);
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
          await Updates.fetchUpdateAsync();
          console.log(`Reloading app with new remote updates`);
          // ... notify user of update ...
          await Updates.reloadAsync();
        } else {
          console.log('No new update available');
        }
      } catch (e) {
        // handle or log error
        console.log(`Unable to apply remote updated: ${e}`);
      }
      await SplashScreen.hideAsync();
    })();
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <LocaleProvider>
          <AuthProvider>
            <AppContainer />
            <StatusBar style="auto" />
          </AuthProvider>
        </LocaleProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
