import React from 'react';
import { StyleSheet, View, Animated, Easing, PanResponder, Pressable, Platform } from 'react-native';
import { Text } from 'react-native-elements';
import { MaterialIcons } from '@expo/vector-icons';

const TOP_OFFSET = 50;
const HEIGHT = 275;
const HIDDEN_TOP = -(HEIGHT + TOP_OFFSET);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 27,
    right:27,
    top: HIDDEN_TOP,
    minHeight: HEIGHT,
    maxWidth:320,
    marginHorizontal: 'auto',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    paddingHorizontal:38,
    paddingVertical:20,
    ...(Platform.OS === 'web' ? { cursor: 'pointer', userSelect: 'none' } : {}),
  },
  icon: {
    justifyContent: 'center',
    marginBottom:38,
    textAlign: 'center',
  },
  title: {
    marginBottom:12,
    fontWeight:'bold',
    fontSize: 24,
    lineHeight: 24,
    color: '#FFFFFF',
    textAlign: 'center',
    justifyContent: 'center',
  },
  message: {
    fontSize: 16,
    lineHeight: 24,
    color: '#FFFFFF',
    textAlign: 'center',
    justifyContent: 'center',
  },
});

const DEFAULT_WAIT = 4000;

const ScanToast = ({ title, message, status, duration, onHide, onPress }) => {
  const slideInAnim = React.useRef(new Animated.Value(HIDDEN_TOP)).current;
  let timeout;

  const slideOut = (fromTouch=false) => {
    Animated.timing(
      slideInAnim,
      {
        toValue: HIDDEN_TOP,
        duration: 250,
        easing: fromTouch ? Easing.elastic() : Easing.back(),
        useNativeDriver: false,
      }
    ).start(onHide);
  };

  const slideIn = () => {
    Animated.timing(
      slideInAnim,
      {
        toValue: TOP_OFFSET,
        duration: 500,
        easing: Easing.elastic(),
        useNativeDriver: false,
      }
    ).start(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(slideOut, duration || DEFAULT_WAIT);
    });
  };

  const panResponder = React.useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: () => false,
      onStartShouldSetPanResponderCapture: () => false,
      onMoveShouldSetPanResponder: (event, gesture) => {
        const { dy } = gesture;
        return Math.abs(dy) > 20;
      },
      onMoveShouldSetPanResponderCapture: () => false,
      onPanResponderGrant: () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      },
      onPanResponderMove: (event, gesture) => {
        const { moveY, y0 } = gesture;
        slideInAnim.setValue(moveY - y0 + TOP_OFFSET);
      },
      onPanResponderRelease: (event, gesture) => {
        const { moveY } = gesture;
        if (moveY < 200) {
          slideOut(true);
        } else {
          slideIn();
        }
      },
    })
  ).current;

  React.useEffect(() => {
    slideIn();
  }, [slideInAnim])

  let backgroundColor, icon;
  if (status === 'success') {
    backgroundColor = '#05944F';
    icon = 'check-circle';
  } else {
    backgroundColor = '#E11900';
    icon = 'cancel';
  }
  return (
    <Animated.View style={[styles.container, { backgroundColor, top: slideInAnim }]} {...panResponder.panHandlers} >
      <Pressable
        onPress={() => {
          onPress();
          slideOut();
        }}
      >
        <View>
          <MaterialIcons name={icon} size={100} color="white" style={styles.icon} />
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.message}>{message}</Text>
        </View>
      </Pressable>
    </Animated.View>
  );
};

export default ScanToast;
