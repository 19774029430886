import React from 'react';
import { View, ActivityIndicator, StyleSheet, Platform } from 'react-native';
import { Button, Text } from 'react-native-elements';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LocaleContext } from '../../contexts/LocaleContext';
import { FontAwesome } from '@expo/vector-icons';
import Modal from '../shared/Modal';
import TextInput from '../shared/TextInput';
import DropDownPicker from 'react-native-dropdown-picker';
import { DataContext } from '../../contexts/DataContext';
import { ScannerContext } from '../../contexts/ScannerContext';
import { toastError, toastNotify } from '../../helpers/toast';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

const styles = StyleSheet.create({
  itemContainer: {
    paddingVertical: 16,
    width: '100%',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E2E2E2',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  itemLabel: {
    fontWeight: 'normal',
    color: '#545454',
    fontSize: 14,
    lineHeight: 20,
    marginTop: 4,
  },
  itemValue: {
    fontWeight: 'normal',
    color: '#000',
    fontSize: 16,
    lineHeight: 20,
  },
  itemHeader: {
    fontWeight: 'bold',
    color: '#545454',
    fontSize: 14,
    lineHeight: 20,
    marginTop: 4,
  },
  itemEditButton: {
    marginLeft: 20,
  },
});

const AttendeeAttributeInput = ({ spec, value, initialValue, onChange }) => {
  const { t } = React.useContext(LocaleContext);

  if (spec.type == 'select') {
    return (
      <DropDownPicker
        items={spec.options.map((o) => ({ label: o, value: o }))}
        defaultValue={initialValue}
        onChangeItem={(item) => onChange(item.value)}
        containerStyle={{height: 40}}
        style={{backgroundColor: '#fafafa'}}
        itemStyle={{
          justifyContent: 'flex-start',
          zIndex: 1000,
        }}
        zIndex={1000}
        zIndexInverse={10000}
      />
    );
  } else if (spec.type == 'checkbox') {
    return (
      <DropDownPicker
        items={[
          { label: t('common.yes'), value: '1' },
          { label: t('common.no'), value: '0' },
        ]}
        defaultValue={initialValue ? '1' : '0'}
        onChangeItem={(item) => onChange(item.value)}
        containerStyle={{height: 40}}
        style={{backgroundColor: '#fafafa'}}
        itemStyle={{
          justifyContent: 'flex-start',
          zIndex: 1000,
        }}
        zIndex={1000}
        zIndexInverse={10000}
      />
    );
  } else if (spec.type == 'choice') {
    return (
      <DropDownPicker
        items={spec.options.map((o) => ({ label: o, value: o }))}
        multiple={!!spec.allow_multiple}
        defaultValue={spec.allow_multiple ? initialValue : (Array.isArray(initialValue) ? initialValue && initialValue.length > 0 && initialValue[0] : initialValue)}
        onChangeItem={(item) => {
          console.log(`[onChangeItem]: item: `, item);
          onChange(spec.allow_multiple ? item : [item.value]);
        }}
        containerStyle={{height: 40}}
        style={{backgroundColor: '#fafafa'}}
        itemStyle={{
          justifyContent: 'flex-start',
          zIndex: 1000,
        }}
        zIndex={1000}
        zIndexInverse={10000}
      />
    );
  } else if (spec.type == 'text') {
    return (
      <TextInput
        onChangeText={onChange}
        value={value || ''}
        returnKeyType='next'
        autoCapitalize='none'
        onSubmitEditing={() => submit()}
      />
    );
  }
};


const TicketInfoSection = ({ event, attendee, style }) => {
  const { t, formatDateAndTime } = React.useContext(LocaleContext);

  const EditItemModal = ({ label, initialValue, spec, onClose }) => {
    const [value, setValue] = React.useState(initialValue);
    const [saving, setSaving] = React.useState(false);

    const { updateAttendee } = React.useContext(DataContext);
    const { startKeyboardMonitor, stopKeyboardMonitor } = React.useContext(ScannerContext);

    React.useEffect(() => {
      stopKeyboardMonitor();
    }, []);

    const handleClose = () => {
      startKeyboardMonitor();
      onClose();
    };

    return (
      <Modal
        onClose={handleClose}
        heading={label}
        primaryAction={{
          content: t('common.save'),
          onPress: async () => {
            try {
              setSaving(true);
              await updateAttendee({
                eventId: event.id,
                attendeeId: attendee.id,
                params: {
                  ticket_attributes: {
                    [spec.name]: value,
                  },
                },
              });
              toastNotify({
                title: t('messages.ticket_update'),
                message: t('messages.ticket_update_success'),
              });
            } catch (error) {
              console.log(`error: ${error}`);
              toastError({
                title: t('messages.ticket_update'),
                message: t('messages.ticket_update_failed'),
              });
            } finally {
              setSaving(false);
              handleClose();
            }
          },
        }}
        secondaryAction={{
          content: t('common.cancel'),
          onPress: handleClose,
        }}
      >
        <View style={{paddingTop: 18, paddingLeft: 9, paddingRight: 9, width: '100%'}}>
          <View style={Platform.OS === 'android' ? {marginBottom:20} : {marginBottom:20, zIndex: 1000}}>
            <AttendeeAttributeInput
              spec={spec}
              value={value}
              initialValue={initialValue}
              onChange={(v) => setValue(v)}
            />
          </View>
        </View>
      </Modal>
    );
  };

  const InfoItem = ({ label, value, spec }) => {
    const [editModalOpen, setEditModalOpen] = React.useState(false);

    if (spec) {
      let displayValue = value;
      if (spec.type == 'choice') {
        displayValue = Array.isArray(value) ? value.join(', ') : value;
      }
      if (spec.type == 'checkbox') {
        displayValue = value ? t('common.yes') : t('common.no');
      }

      if (spec.type == 'header') {
        return (
          <View style={styles.itemContainer}>
            <Text style={styles.itemHeader}>{label}</Text>
          </View>
        )
      } else {
        if (spec.checkin_editable) {
          return (
            <View style={styles.itemContainer}>
              <View style={{flexDirection:'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <View>
                  <View style={{flexDirection:'row', alignItems: 'center'}}>
                    <Text style={styles.itemLabel}>{label}</Text>
                    {spec.required && !value && <Text style={{color:'red', marginLeft: 5}}>(required)</Text>}
                  </View>
                  <Text style={styles.itemValue}>{displayValue}</Text>
                </View>
                <View>
                  <Button
                    containerStyle={styles.itemEditButton}
                    type='clear'
                    icon={<FontAwesome name="edit" size={24} color="black" />}
                    onPress={() => setEditModalOpen(true)}
                  />
                </View>
              </View>
              {editModalOpen &&
                <EditItemModal
                  label={label}
                  initialValue={value}
                  spec={spec}
                  onClose={() => setEditModalOpen(false)}
                />
              }
            </View>
          )
        } else {
          return (
            <View style={styles.itemContainer}>
              <Text style={styles.itemLabel}>{label}</Text>
              <Text style={styles.itemValue}>{displayValue}</Text>
            </View>
          )
        }
      }
    } else {
      return (
        <View style={styles.itemContainer}>
          <Text style={styles.itemLabel}>{label}</Text>
          <Text style={styles.itemValue}>{value}</Text>
        </View>
      );
    }
  };

  let ticketTypeTitle = attendee && attendee.ticket_type.title;
  if (event && event.has_seating && attendee && attendee.seat_label && attendee.seat_label.length > 0) {
    ticketTypeTitle = `${attendee.ticket_type.title} / ${attendee.seat_label}`;
  }

  return (
    <View style={[ style, { alignItems: 'flex-start', paddingHorizontal: 23 }]}>
      <InfoItem label={t('attendee.ticket_number')} value={`#${attendee.ticket_number}`} />
      {event && event.ticket_types && event.ticket_types.length > 0 ? <InfoItem label={t('attendee.ticket_type')} value={ticketTypeTitle} /> : null}
      {attendee.email ? <InfoItem label={t('attendee.email')} value={attendee.email} /> : null}
      {attendee.phone ? <InfoItem label={t('attendee.phone')} value={attendee.phone} /> : null}
      {event && event.date_type === 'recurring' ? <InfoItem label={t('attendee.scheduled_for')} value={formatDateAndTime(attendee.scheduled_event, event && event.timezone)} /> : null}
      <InfoItem label={t('attendee.purchase_date')} value={formatDateAndTime(attendee.purchased_at, event && event.timezone)} />
      {event && event.show_ticket_price ? <InfoItem label={t('attendee.ticket_price')} value={`$${parseFloat(attendee.ticket_type.price || 0).toFixed(2)}`} /> : null}
      {attendee.checkin_expires_in_days ? <InfoItem label={t('attendee.ticket_expiry')} value={dayjs(attendee.checkin_ticket_expires_at).fromNow()} /> : null}
      {attendee.ticket_attributes && attendee.ticket_attributes.map((attribute, index) => (
        (!attribute.spec.except_tickets || attribute.spec.except_tickets[attendee.ticket_type.id.to_s] != '1') &&
        !attribute.spec.checkin_hide ?
        (
          <InfoItem key={`attr_${index}`} label={attribute.spec.label} value={attribute.value} spec={attribute.spec} />
        ) : null
      ))}
    </View>
  );
};

export default TicketInfoSection;
