import React from 'react';
import { SafeAreaView, Text, FlatList, Animated } from 'react-native';
import { ListItem } from 'react-native-elements';
import { DataContext } from '../contexts/DataContext';
import { toastError } from '../helpers/toast';
import { LocaleContext } from '../contexts/LocaleContext';

// const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const fetchErrorToast = () => {
  toastError({
    title: 'Unable to get events',
    message: 'There was a problem communicating with the Evey backend, check your connection and try again.',
  });
}

const EventsScreen = ({ navigation }) => {
  const { fetchEvents, events, loading, selectEvent } = React.useContext(DataContext);
  const { t } = React.useContext(LocaleContext);

  React.useEffect(() => {
    (async () => {
      try {
        await fetchEvents();
      } catch (error) {
        fetchErrorToast();
      }
    })();
  }, []);

  const renderItem = ({ item }) => {
    return (
      <ListItem
        bottomDivider
        onPress={() => {
          selectEvent(item.id);
          navigation.navigate('Attendees', {
            eventId: item.id,
          });
        }}
      >
        <ListItem.Content>
          <ListItem.Title>{item.title}</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron />
      </ListItem>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
      <FlatList
        data={events}
        renderItem={renderItem}
        keyExtractor={(item) => `${item.id}`}
        refreshing={loading}
        onRefresh={async () => {
          try {
            await fetchEvents({ useCache: false });
          } catch (error) {
            fetchErrorToast();
          }
        }}
      />
    </SafeAreaView>
  );
}

export default EventsScreen;
