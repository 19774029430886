import React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { Text } from 'react-native-elements';
import { MaterialIcons } from '@expo/vector-icons';
import { DataContext } from '../../contexts/DataContext';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import Banner from '../shared/Banner';
import Button from '../shared/Button';
import { toastError, toastNotify } from '../../helpers/toast';
import { LocaleContext } from '../../contexts/LocaleContext';
import { convertUtcDateToTimezone } from '../../helpers/time';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const styles = StyleSheet.create({
  actionButton: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  checkinInfoContainer: {
    marginTop: 20,
  },
  infoText: {
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: '#545454',
  },
});

const TicketActionSection = ({ event, attendee, style }) => {
  const [savingCancel, setSavingCancel] = React.useState(false);
  const [savingCheckin, setSavingCheckin] = React.useState(false);
  const { checkinAttendee, cancelCheckinAttendee } = React.useContext(DataContext);
  const { t, formatDateAndTime } = React.useContext(LocaleContext);

  const checkInEdiableAttributes = attendee.ticket_attributes ?
    attendee.ticket_attributes.filter((attr) => (
      !attr.spec.except_tickets ||
      attr.spec.except_tickets[attendee.ticket_type.id.to_s] != '1'
    ) && !attr.spec.checkin_hide && attr.spec.checkin_editable && attr.spec.required) : [];

  const isValid = checkInEdiableAttributes.filter((attr) => attr.value != null && attr.value != '').length == checkInEdiableAttributes.length;

  const fetchErrorToast = (title) => {
    toastError({
      title: title,
      message: t('messages.fetch_tickets_failed'),
    });
  }

  let actionMarkup;

  if (attendee.cancelled_at) {
    const cancelText = `${t('attendee.ticket_cancelled_on')} ${dayjs(attendee.cancelled_at).format('MMMM D, YYYY [at] h:mm A')}`;
    actionMarkup = (
      <Banner title={t('attendee.ticket_cancelled')} status='critical' containerStyle={{ marginTop: 20 }}>
        {attendee.cancel_reason ? (
          <View>
            <Text>{cancelText} {t('attendee.with_reason')}:</Text>
            <Text style={{ marginTop: 5 }}>{attendee.cancel_reason}</Text>
          </View>
        ) : (
          <Text>{cancelText}.</Text>
        )}
      </Banner>
    );
  } else {
    if (attendee.checkin_enabled) {
      if (attendee.checkin_ticket_expired) {
        actionMarkup = (
          <Banner title={t('attendee.ticket_expired')} status='critical' containerStyle={{ marginTop: 20 }}>
            <Text>This ticket expired {dayjs(attendee.checkin_ticket_expires_at).fromNow()}</Text>
          </Banner>
        );
      } else {
        const checkinButton = (
          <Button
            containerStyle={styles.actionButton}
            title={t('attendee.checkin_action')}
            status='success'
            loading={savingCheckin}
            disabled={!isValid}
            icon={<MaterialIcons name="check-circle" size={24} color="white" style={{ marginRight: 8 }} />}
            onPress={async () => {
              try {
                setSavingCheckin(true);
                await checkinAttendee({ eventId: event.id, attendeeId: attendee.id });
                toastNotify({
                  title: t('messages.checkin'),
                  message: t('messages.checked_in_successfully'),
                });
              } catch (error) {
                console.log(`error: ${error}`);
                fetchErrorToast(t('messages.checkin_failed'));
              } finally {
                setSavingCheckin(false);
              }
            }}
          />
        );

        if (attendee.checkedin_at) {
          if (attendee.ticket_type.allow_checkin_cancel) {
            const cancelButton = (
              <Button
                containerStyle={styles.actionButton}
                title={t('attendee.checkin_cancel_action')}
                status='critical'
                loading={savingCancel}
                icon={<MaterialIcons name="cancel" size={24} color="white" style={{ marginRight: 8 }} />}
                onPress={async () => {
                  try {
                    setSavingCancel(true);
                    await cancelCheckinAttendee({ eventId: event.id, attendeeId: attendee.id });
                    toastNotify({
                      title: t('messages.checkin_cancelled'),
                      message: t('messages.checkin_cancelled_message'),
                    });
                  } catch (error) {
                    console.log(`error: ${error}`);
                    fetchErrorToast(t('messages.checkin_failed'));
                  } finally {
                    setSavingCancel(false);
                  }
                }}
              />
            );
            if (attendee.checkin_limit > 1 && attendee.checkin_count < attendee.checkin_limit) {
              actionMarkup = [checkinButton, cancelButton];
            } else {
              actionMarkup = cancelButton;
            }
          }
        } else {
          actionMarkup = checkinButton;
        }
      }
    } else {
      actionMarkup = (
        <Banner title={t('attendee.checkin_disabled')} status='critical' containerStyle={{ marginTop: 20 }}>
          <Text>{attendee.ticket_type.checkin_disabled_message}</Text>
        </Banner>
      );
    }
  }

  let checkedInInfoMarkup;
  if (!attendee.cancelled_at && attendee.checkin_enabled) {
    if (attendee.checkin_limit == 1) {
      if (attendee.checkedin_at) {
        checkedInInfoMarkup = (
          <View style={styles.checkinInfoContainer}>
            <Text style={styles.infoText}>
              This ticket has already been checked in on&nbsp;
              {formatDateAndTime(attendee.checkedin_at, event && event.timezone)}
              {attendee.checkedin_by ? ` by ${attendee.checkedin_by.email}` : ''}
            </Text>
          </View>
        );
      }
    } else {
      const lastCheckedInText = attendee.checkedin_at ? (
          `${t('attendee.last_checked_in_on')} ${formatDateAndTime(attendee.checkedin_at, event && event.timezone)}${attendee.checkedin_by ? ` ${t('common.by')} ${attendee.checkedin_by.email}` : ''}`
      ) : '';
      if (attendee.checkins_remaining == 0) {
        checkedInInfoMarkup = (
          <Banner title={t('attendee.no_checkins_remaining')} status='warning' containerStyle={styles.checkinInfoContainer}>
            <Text>{lastCheckedInText}</Text>
          </Banner>
        );
      } else {
        checkedInInfoMarkup = (
          <View style={styles.checkinInfoContainer}>
            <Text style={styles.infoText}>
              {t('attendee.checkins_remaining_message', { count: attendee.checkins_remaining })}
            </Text>
            {lastCheckedInText ? <Text style={styles.infoText}>{lastCheckedInText}</Text> : null}
          </View>
        );
      }
    }
  }

  return (
    <View style={{ ...style, width: '100%' }}>
      {actionMarkup}
      {checkedInInfoMarkup}
    </View>
  );
};

export default TicketActionSection;
