import React from 'react';
import { Platform } from 'react-native';
import { AuthContext } from '../contexts/AuthContext';
import { storeData, fetchData } from '../helpers/storage';
import { CAMERA_TYPE_BACK, CAMERA_TYPE_FRONT } from '../components/scan/CameraScanner';

export const SettingsContext = React.createContext();

export const SettingsProvider = ({ children }) => {
  const [autoCheckin, setAutoCheckin] = React.useState(null);
  const [selectedCameraType, setSelectedCameraType] = React.useState(CAMERA_TYPE_BACK);
  const [scanningDelay, setScanningDelay] = React.useState(null);
  const [defaultSort, setDefaultSort] = React.useState(null);
  const [timezone, setTimezone] = React.useState(null);
  const [autoTimezone, setAutoTimezone] = React.useState(null);

  const { userEmail } = React.useContext(AuthContext);

  const storeSetting = async (name, value) => {
    console.log(`Storing settings ${name}: ${value}`);
    await storeData(`Settings:${userEmail}:${name}`, `${value}`);
    if (name === 'autoCheckin') {
      setAutoCheckin(value);
    } else if (name === 'selectedCameraType') {
      setSelectedCameraType(parseInt(value));
    } else if (name === 'scanningDelay') {
      setScanningDelay(parseInt(value));
    } else if (name === 'defaultSort') {
      setDefaultSort(value);
    } else if (name === 'timezone') {
      setTimezone(value);
    } else if (name === 'autoTimezone') {
      setAutoTimezone(value);
    }
  };

  const fetchSettingBoolean = async (name) => {
    const result = await fetchData(`Settings:${userEmail}:${name}`);
    // console.log(`Settings init ${name}: ${result}`);
    return result === true || result === 'true';
  };
  const fetchSettingInt = async (name) => {
    const result = await fetchData(`Settings:${userEmail}:${name}`);
    // console.log(`Settings init ${name}: ${result}`);
    try {
      return parseInt(result);
    } catch {
      return null;
    }
  };
  const fetchSettingString = async (name) => {
    const result = await fetchData(`Settings:${userEmail}:${name}`);
    // console.log(`Settings init ${name}: ${result}`);
    return result;
  };

  const fetchAutoCheckin = async (useCache=true) => {
    if (autoCheckin !== null && useCache) {
      return autoCheckin;
    } else {
      const result = await fetchSettingBoolean('autoCheckin');
      setAutoCheckin(result);
      return result;
    }
  }

  const fetchScanningDelay = async (useCache=true) => {
    if (scanningDelay !== null && useCache) {
      return scanningDelay;
    } else {
      const result = await fetchSettingInt('scanningDelay');
      setScanningDelay(result);
      return result;
    }
  }

  const fetchDefaultSort = async (useCache=true) => {
    if (defaultSort !== null && useCache) {
      return defaultSort;
    } else {
      const result = await fetchSettingString('defaultSort');
      setDefaultSort(result);
      return result;
    }
  }

  const loadSettings = async () => {
    console.log(`Loading settings for ${userEmail}`);
    setAutoCheckin((await fetchSettingBoolean('autoCheckin')));
    setSelectedCameraType((await fetchSettingInt('selectedCameraType')) || CAMERA_TYPE_BACK);
    setDefaultSort((await fetchSettingString('defaultSort')));
  };

  React.useEffect(() => {
    loadSettings();
  }, [userEmail]);

  React.useEffect(() => {
    loadSettings();
  }, []);

  const settingsContext = {
    autoCheckin,
    scanningDelay: scanningDelay == null ? 2000 : scanningDelay,
    defaultSort,
    timezone,
    autoTimezone,
    fetchAutoCheckin, // needed for non-top level callers
    fetchScanningDelay,
    fetchDefaultSort,
    selectedCameraType,
    updateSetting: storeSetting,
    CAMERA_TYPE_BACK,
    CAMERA_TYPE_FRONT,
  };

  return (
    <SettingsContext.Provider value={settingsContext}>
      {children}
    </SettingsContext.Provider>
  );
};
