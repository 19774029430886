import { Notifier } from '@airbrake/browser';
import { env } from './environment';
import Constants from 'expo-constants';
import { commit as commitVersion } from '../../version.json';

export const notifyError = async (error, params={}, context={}) => {
  const airbrake = new Notifier({
    host: 'https://kable-errbit.herokuapp.com',
    projectId: 1,
    projectKey: '2706da3118c347da6f3bf7cba254178f',
    environment: env,
  });

  console.log(`[Airbrake] Sending error ${error}: ${JSON.stringify(params)}`);
  console.log(`[Airbrake] Backtrace: ${error.stack}`);
  // if (env == 'production') {
    await airbrake.notify({
      error,
      params: {
        ...(params || {}),
        nativeAppVersion: Constants.nativeAppVersion,
        nativeBuildVersion: Constants.nativeBuildVersion,
        commitVersion,
      },
      context,
    });
  // }
};
