import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import EventsScreen from './EventsScreen';
import AttendeesScreen from './AttendeesScreen';
import AttendeeScreen from './AttendeeScreen';
import { AuthContext } from '../contexts/AuthContext';
import { theme } from '../../app.json';
import { LocaleContext } from '../contexts/LocaleContext';

const Stack = createStackNavigator();

const CheckInContainer = ({ navigation }) => {
  const { attemptingAttendee, setAttemptingAttendee } = React.useContext(AuthContext);
  const { t } = React.useContext(LocaleContext);

  React.useEffect(() => {
    if (attemptingAttendee) {
      console.log(`Redirecting back to ${JSON.stringify(attemptingAttendee)}`);
      navigation.navigate('Attendee', attemptingAttendee);
    }
  }, []);

  return (
    <Stack.Navigator
      initialRouteName="Events"
      screenOptions={{
        headerStyle: {
          backgroundColor: theme.primaryColor,
        },
        headerTintColor: theme.primaryFontColor,
        headerTitleStyle: {
          fontWeight: 'bold',
        },
      }}
    >
      <Stack.Screen name="Events" component={EventsScreen} options={{ title: t('navigation.events') }} />
      <Stack.Screen name="Attendees" component={AttendeesScreen} options={{ title: t('navigation.attendees') }} />
      <Stack.Screen name="Attendee" component={AttendeeScreen} options={{ title: t('navigation.attendee') }} />
    </Stack.Navigator>
  );
};

export default CheckInContainer;
