import React from 'react';
import { SafeAreaView, View, Image, Pressable, ScrollView, Linking, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Text, Button, Input } from 'react-native-elements';
import { APP_URL, CHECKIN_API_KEY } from '../helpers/api';
import Banner from '../components/shared/Banner';
import HeaderBackButton from '../components/shared/HeaderBackButton';
import { AuthContext } from '../contexts/AuthContext';
import TextInput from '../components/shared/TextInput';
import PrimaryButton from '../components/shared/PrimaryButton';
import { LocaleContext } from '../contexts/LocaleContext';

const ForgotPasswordScreen = ({ navigation }) => {
  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');

  const { isLoggedIn } = React.useContext(AuthContext);
  const { t } = React.useContext(LocaleContext);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <HeaderBackButton
          onPress={()=>{
            const index = navigation.dangerouslyGetState().index;
            if (index == 0) {
              navigation.reset({
                index: 0,
                routes: [isLoggedIn ? { name: 'Main' } : { name: 'Login' }]
              });
            } else {
              navigation.goBack();
            }
          }}
        />
      ),
    });
  }, [navigation]);

  const submit = async () => {
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    const response = await fetch(`${APP_URL}/checkin/api/sessions/forgot_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Token ${CHECKIN_API_KEY}`,
      },
      body: JSON.stringify({ email }),
    });
    const result = await response.json();
    if (result.error) {
      setErrorMessage(result.error);
    } else if (result.success) {
      setSuccessMessage(result.success);
    }
    setLoading(false);
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
      <KeyboardAwareScrollView contentContainerStyle={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
        <View style={{ paddingLeft: 28, paddingRight: 28, width: '100%' }}>
          {successMessage ?
            <Banner status='info' containerStyle={{ marginBottom: 20 }}>
              <Text>{successMessage}</Text>
            </Banner>
            : null
          }
          <Text style={{ marginBottom: 20, marginTop: 36, textAlign: 'center', fontSize: 24, lineHeight: 44, fontWeight: '500' }}>{t('navigation.forgot_password')}</Text>
          <Text style={{ marginBottom: 40, paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>{t('login.forgot_password_instructions')}</Text>
          <TextInput
            placeholder='email@address.com'
            label={t('login.email')}
            onChangeText={setEmail}
            value={email}
            autoCompleteType='email'
            keyboardType='email-address'
            returnKeyType='next'
            autoCapitalize='none'
            onSubmitEditing={() => submit()}
          />
          <PrimaryButton
            title={t('common.submit')}
            onPress={() => submit()}
            loading={loading}
          />
          {errorMessage ? (
            <Text style={{ color: 'red', marginTop: 20, textAlign: 'center' }}>{errorMessage}</Text>
          ) : (
            null
          )}
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

export default ForgotPasswordScreen;
