import React from 'react';
import { Platform, NativeModules, NativeEventEmitter, DeviceEventEmitter, Alert, Keyboard } from 'react-native';
import { SettingsContext } from '../contexts/SettingsContext';
import { DataContext } from '../contexts/DataContext';
import { toastNotify, toastError } from '../helpers/toast';
import { CommonActions } from '@react-navigation/native';
import ScanToast from '../components/scan/ScanToast';

const { SocketMobile, KeyEvent, ScanBroadcast } = NativeModules;
let emitter, keyEmitter, scanBroadcast;
if (Platform.OS !== 'web') {
  try {
    emitter = new NativeEventEmitter(SocketMobile);
  } catch (error) {
    console.log(`Unable to load SocketMobile native module emitter: ${error}`);
  }
  try {
    keyEmitter = new NativeEventEmitter(KeyEvent);
  } catch (error) {
    console.log(`Unable to load KeyEvent native module emitter: ${error}`);
  }
  try {
    scanBroadcast = new NativeEventEmitter(ScanBroadcast);
  } catch (error) {
    console.log(`Unable to load ScanBroadcast native module emitter: ${error}`);
  }
}

const DECODED_DATA_LISTENER = 'DecodedData';
const STATUS_DEVICE_LISTENER = 'StatusDeviceChanged';

export const ScannerContext = React.createContext();

export const ScannerProvider = ({ navigation, children }) => {
  const [scanners, setScanners] = React.useState([]);
  const [scanToast, setScanToast] = React.useState(null);
  const [keyboardMonitorActive, _setKeyboardMonitorActive] = React.useState(true);

  const keyboardMonitorActiveRef = React.useRef(keyboardMonitorActive);
  const setKeyboardMonitorActive = (v) => {
    keyboardMonitorActiveRef.current = v;
    _setKeyboardMonitorActive(v);
  };

  // console.log(`keyboardMonitorActive: ${keyboardMonitorActive}`);

  const { fetchAutoCheckin, fetchScanningDelay } = React.useContext(SettingsContext);
  const { checkinAttendee, cancelCheckinAttendee, saving, scanTicket: remoteScanTicket, scanning: remoteScanning } = React.useContext(DataContext);

  const start = async () => {
    if (Platform.OS !== 'web') {
      console.log(`Initializing Socket Mobile`);
      try {
        await SocketMobile.start('ios:com.eveyevents.CheckInWeb', '438a57bd-dcde-ea11-a813-000d3a33b4ee', 'MC0CFQCC8uoHzx0ZFV0Hl1evp3hv7xcT9QIUQMvBXjSZ1DqAZRjdo8bD/WhJ6ZQ=');
      } catch (error) {
        console.log(`Unable to load socket mobile connector: ${error}`);
        // TODO: send to errbit
      }
    }
  }

  const stop = async () => {
    if (Platform.OS !== 'web') {
      console.log(`Shutting down Socket Mobile`);
      try {
        await SocketMobile.stop();
      } catch (error) {
        console.log(`Unable to shut down socket mobile connector: ${error}`);
        // TODO: send to errbit
      }
    }
  }

  const setDataListener = (callback) => {
    try {
      emitter.addListener(DECODED_DATA_LISTENER, callback);
    } catch (error) {
      console.log(`Unable to listender for socket mobile data: ${error}`);
    }
  };

  const setDeviceStatusListener = (callback) => {
    try {
      emitter.addListener(STATUS_DEVICE_LISTENER, ({ status }) => {
        callback(status);
      });
    } catch (error) {
      console.log(`Unable to listener for socket mobile data: ${error}`);
    }
  };

  const clearAllListeners = () => {
    try {
      emitter.removeAllListeners(DECODED_DATA_LISTENER);
      emitter.removeAllListeners(STATUS_DEVICE_LISTENER);
    } catch (error) {
      console.log(`Unable to clear socket mobile config: ${error}`);
    }
  };

  const getConnectedDevices = async () => {
    if (Platform.OS !== 'web') {
      console.log('Fetching connected Socket Mobile devices');
      try {
        return await SocketMobile.getConnectedDeviceNames();
      } catch (error) {
        console.log(`Unable to fetch connected socket mobile devices: ${error}`);
        // TODO: send to errbit
        return [];
      }
    }
  };

  const navigateToAttendee = ({ eventId, attendeeId }) => {
    // navigation.navigate('Check-In', {
    //     screen: 'Attendee',
    //     params: {
    //       eventId,
    //       attendeeId,
    //     }
    // });

    const action = CommonActions.reset({
      routes: [
        {
          name: 'Check-In',
          state: {
            routeNames: ['Events', 'Attendees', 'Attendee'],
            routes: [
              { name: 'Events' },
              { name: 'Attendees', params: { eventId, skipNoAuthSignOut: true }, },
              { name: 'Attendee', params: { eventId, attendeeId }, },
            ],
            index: 2,
          },
        },
      ],
      index: 0,
    });
    navigation.dispatch(action);
  }

  const onScan = async ({ data, onReset }) => {
    var m = data.match(/^https?:\/\/.*evey.+\/c\/attendees\/([0-9]+)\?event_id=([0-9]+)/);
    if (m) {
      let eventId = m[2];
      let attendeeId = m[1];

      if (eventId == 36658 && attendeeId == 4094192) {
        // California United printed awesome physical tickets for their VIP passes but used the wrong QR code... replace it with the right one so scanning works
        attendeeId = 4103250;
      }

      console.log(`Found ticket for event ${eventId}: ${attendeeId}`);
      const autoCheckin = await fetchAutoCheckin();
      if (autoCheckin) {
        console.log(`Auto check-in enabled, checking attendee in`);
        try {
          await checkinAttendee({ eventId, attendeeId });
          setScanToast({
            title: 'Checked In',
            message: 'This ticket was checked in successfully',
            status: 'success',
            onPress: () => {
              navigateToAttendee({ eventId, attendeeId });
              // setScanToast(null);
            },
            onHide: () => {
              if (onReset) {
                onReset();
              }
            },
          });
        } catch (error) {
          if (error.name === 'ApiConflict') {
            setScanToast({
              title: 'Unable to check-in',
              message: 'This ticket is already checked-in or has reached it\'s limit',
              status: 'fail',
              onPress: () => {
                navigateToAttendee({ eventId, attendeeId });
                // setScanToast(null);
              },
              onHide: () => {
                if (onReset) {
                  onReset();
                }
              },
            });
          } else {
            setScanToast({
              title: 'Unable to check-in',
              message: 'Make sure this is a valid ticket and check your connection.',
              status: 'fail',
              onHide: () => {
                if (onReset) {
                  onReset();
                }
              },
            });
          }
        }
      } else {
        navigateToAttendee({ eventId, attendeeId });
      }
    } else {
      console.log(`Can't recognize ticket code: ${data}`);
      const attendees = await remoteScanTicket({ code: data });
      if (attendees && attendees.length > 0) {
        console.log(`Remote scan found ticket: ${JSON.stringify(attendees)}`);
        const attendee = attendees[0];
        navigateToAttendee({ eventId: attendee.event_id, attendeeId: attendee.id });
      } else {
        await (new Promise((resolve, reject) => {
          Alert.alert(
            "Ticket",
            "This does not appear to be a QR code from a ticket.",
            [
              {
                text: "Ok",
                onPress: () => {
                  if (onReset) {
                    onReset();
                  }
                },
              }
            ],
            { cancelable: false }
          );
        }));
      }
    }
  };

  React.useEffect(() => {
    if (Platform.OS !== 'web') {
      (async () => {
        await start();
        const result = await getConnectedDevices();
        setScanners(result);

        setDeviceStatusListener((status) => {
          console.log(`Socket mobile status updated: ${status}`);
          (async () => {
            const result = await getConnectedDevices();
            setScanners(result);
          })();
        });

        setDataListener(({ data }) => {
          console.log(`Socket mobile scanned: ${data}`);
          onScan({ data });
        });
      })();
    }

    return () => {
      if (Platform.OS !== 'web') {
        clearAllListeners();
        stop();
      }
    };
  }, []);

  React.useEffect(() => {
    let listener;
    let buffer = '';
    let reading = false;
    let timeout;

    const handleKeyEvent = (key, keyCode) => {
      // console.log(`Key event: ${JSON.stringify(key)} / ${keyCode}`);
      const finishReading = () => {
        reading = false;
        let code = buffer.trim();
        // console.log(`code: ${code}`);
        if (code.indexOf('https:Shift//') !== -1) {
          // handle Urovo / IPDA080 scanner (from parkpass)
          code = code.replace(/Shift/g, '');
        }
        code = code.replace(/Shift;/g, ':').replace(/Shift\//g, '?').replace(/Shift-/g, '_');
        // console.log(`Code: ${code}`);
        if (timeout) {
          clearTimeout(timeout);
        }
        if (code && code.length) {
          // console.log(`keyboardMonitorActive2: ${keyboardMonitorActiveRef.current}`);
          if (keyboardMonitorActiveRef.current) {
            // console.log(`Typing finished...`)
            Keyboard.dismiss();
            onScan({ data: code });
          }
        }
      }
      if (reading) {
        if (Platform.OS === 'android') {
          if (keyCode == 59) {
            key = 'Shift';
          }
        }
        if (key === '\r' || key === '\n') {
          finishReading();
        } else {
          buffer = buffer + key;
        }
      } else {
        reading = true;
        buffer = key || '';
        (async () => {
          const delay = await fetchScanningDelay();
          // console.log(`Waiting ${delay}`)
          timeout = setTimeout(() => {
            finishReading();
          }, delay || 2000);
        })();
      }
    }

    if (Platform.OS !== 'web') {
      if (listener) {
        listener.remove();
        listener = null;
      }
      try {
        if (Platform.OS === 'ios') {
          listener = keyEmitter.addListener('onKeyUp', (key) => handleKeyEvent(key.pressedKey));
        } else {
          listener = DeviceEventEmitter.addListener('onKeyUp', (key) => handleKeyEvent(key.pressedKey, key.keyCode));
        }
      } catch (error) {
        console.log(`Unable to add listener for scanner keypress data: ${error}`);
      }
    } else {
      document.addEventListener('keyup', (e) => {
        let key;
        if (e.key === 'Enter') {
          key = '\r';
        } else {
          key = e.key;
        }
        if (keyboardMonitorActiveRef.current) {
          handleKeyEvent(key);
        }
      });
    }

    return () => {
      if (Platform.OS !== 'web') {
        if (listener) {
          listener.remove();
          listener = null;
        }
      }
    };
  }, []);

  // android scan intent
  React.useEffect(() => {
    let listener;

    if (Platform.OS === 'android') {
      listener = DeviceEventEmitter.addListener('onScan', (broadcastResult) => onScan({ data: broadcastResult.barcode_string }));
    }

    return () => {
      if (Platform.OS === 'android') {
        if (listener) {
          listener.remove();
          listener = null;
        }
      }
    };
  }, []);

  const scannerContext = {
    scanners,
    onScan,
    stopKeyboardMonitor: () => setKeyboardMonitorActive(false),
    startKeyboardMonitor: () => setKeyboardMonitorActive(true),
  };

  return (
    <ScannerContext.Provider value={scannerContext}>
      {children}
      {scanToast != null ?
        <ScanToast
          title={scanToast.title}
          message={scanToast.message}
          status={scanToast.status}
          onPress={scanToast.onPress}
          onHide={() => {
            if (scanToast.onHide) {
              scanToast.onHide();
            }
            setScanToast(null);
          }}
        />
      : null}
    </ScannerContext.Provider>
  );
};
