import { get, put, del, post } from '../helpers/api';

const ApiDataProvider = () => {
  const fetchEvents = async () => {
    return await get('/checkin/api/events');
  };

  const fetchEvent = async ({ eventId }) => {
    return await get(`/checkin/api/events/${eventId}`);
  };

  const fetchAttendees = async ({ eventId, search, checkinStatus, ticketStatus, ticketType, scheduledOn, sort, cursor, perPage }) => {
    return await get(`/checkin/api/events/${eventId}/attendees`, {
      search,
      checkinStatus,
      ticketStatus,
      ticketType,
      scheduledOn,
      sort,
      ...(cursor ? { cursor } : null),
      ...(perPage ? { per_page: perPage } : {}),
    }, false);
  };

  const fetchAttendee = async ({ eventId, attendeeId }) => {
    return await get(`/checkin/api/events/${eventId}/attendees/${attendeeId}`);
  };

  const checkinAttendee = async ({ eventId, attendeeId }) => {
    return await post(`/checkin/api/events/${eventId}/attendees/${attendeeId}/checkin`);
  };

  const cancelCheckinAttendee = async ({ eventId, attendeeId }) => {
    // TODO: should be del but the endpoint just toggles for now
    return await del(`/checkin/api/events/${eventId}/attendees/${attendeeId}/checkin`);
  };

  const fetchSchedule = async ({ eventId, startDate, endDate }) => {
    return await get(`/checkin/api/events/${eventId}/schedule`, {
      page_start: startDate,
      page_end: endDate
    });
  };

  const scanTicket = async ({ code }) => {
    return await get(`/checkin/api/scan`, { code });
  };

  const updateAttendee = async ({ eventId, attendeeId, params }) => {
    return await put(`/checkin/api/events/${eventId}/attendees/${attendeeId}`, params);
  };

  return {
    fetchEvents,
    fetchEvent,
    fetchAttendees,
    fetchAttendee,
    checkinAttendee,
    cancelCheckinAttendee,
    fetchSchedule,
    scanTicket,
    updateAttendee,
  };
};

export default ApiDataProvider;
