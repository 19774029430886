import React from 'react';
import { SafeAreaView, InteractionManager, Dimensions, View, Text, StyleSheet, Platform, Image, Linking, Pressable, ScrollView, Vibration, Alert } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import Banner from '../components/shared/Banner';
import { toastNotify, toastError } from '../helpers/toast';
import { DataContext } from '../contexts/DataContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { ScannerContext } from '../contexts/ScannerContext';
import { MaterialIcons } from '@expo/vector-icons';
import { CameraScanner, CAMERA_TYPE_BACK, CAMERA_TYPE_FRONT, requestCameraPermissionsAsync } from '../components/scan/CameraScanner';

const ScanScreen = ({ navigation }) => {
  const [hasPermission, setHasPermission] = React.useState(null);
  const [scannedData, setScannedData] = React.useState(null);
  const [scanning, setScanning] = React.useState(false);

  const { checkinAttendee, cancelCheckinAttendee, saving } = React.useContext(DataContext);
  const { autoCheckin, selectedCameraType, updateSetting } = React.useContext(SettingsContext);
  const { onScan } = React.useContext(ScannerContext);

  const isWeb = Platform.OS === 'web';

  // const { width, height } = Dimensions.get('window');
  const opacity = 'rgba(0, 0, 0, .4)';
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'stretch',
      backgroundColor: 'red',
    },
    layerTop: {
      backgroundColor: opacity,
      height: '30%',
    },
    layerCenter: {
      height: '40%',
      flexDirection: 'row',
    },
    layerLeft: {
      flex: 1,
      backgroundColor: opacity
    },
    focused: {
      flex: 10,
      borderRadius: 20,
    },
    layerRight: {
      flex: 1,
      backgroundColor: opacity
    },
    layerBottom: {
      height: '30%',
      backgroundColor: opacity
    },
  });

  React.useEffect(() => {
    if (!isWeb) {
      (async () => {
        const { status } = await requestCameraPermissionsAsync();
        setHasPermission(status === 'granted');
      })();
    }
  }, []);

  React.useEffect(() => {
    if (!isWeb) {
      if (!scannedData) {
        return;
      }
      Vibration.vibrate();
      onScan({
        data: scannedData,
        onReset: () => setScannedData(null)
      });
    }
  }, [scannedData]);

  React.useEffect(() => {
    const unsubscribeFocus = navigation.addListener('focus', () => {
      console.log(`Start scanning for ticket code`);
      setScanning(true);
      setScannedData(null);
    });

    const unsubscribeBlur = navigation.addListener('blur', () => {
      console.log(`Stop scanning for ticket code`);
      setScanning(false);
    });

    return () => {
      unsubscribeFocus();
      unsubscribeBlur();
    }
  }, [navigation]);

  // useFocusEffect(
  //   React.useCallback(() => {
  //     const task = InteractionManager.runAfterInteractions(() => {
  //       console.log(`Clearing scanned data`);
  //       setScannedData(null);
  //     });
  //
  //     return () => task.cancel();
  //   }, [])
  // );

  const handleBarCodeScanned = ({ type, data }) => {
    if (!scanning || scannedData) {
      return;
    }
    if (type === 'org.iso.QRCode' || type == 256) {
      setScannedData(data);
      // console.log(`Bar code with type ${type} and data ${data} has been scanned!`);
    } else {
      console.log(`Unknown scan type: ${type}`);
    }
  };

  if (isWeb) {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
        <ScrollView>
          <Banner title='Unsupported browser' containerStyle={{ marginTop: 50 }}>
            <Text>Scanning is not supported in this browser. Please download one of our apps.</Text>
          </Banner>
          <View style={{ marginTop: 50 }}>
            <Pressable
              onPress={() => Linking.openURL('https://apps.apple.com/us/app/evey-events-check-in-manager/id1121775093?itsct=apps_box&amp;itscg=30200')}
            >
              <Image
                resizeMode={'contain'}
                style={{  width: '100%', height: 70 }}
                source={{ uri: 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1465430400&h=bcd61eabd05b85b80151493a2650d294' }}
              />
            </Pressable>

            <Pressable
              onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.eveyevents.checkin')}
            >
              <Image
                resizeMode={'contain'}
                style={{  width: '100%', height: 95 }}
                source={{ uri: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' }}
              />
            </Pressable>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }

  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  const ScannerView = () => (
    <View style={{flex: 1}}>
      <View style={styles.layerTop} />
      <View style={styles.layerCenter}>
        <View style={styles.layerLeft} />
        <View style={styles.focused} />
        <View style={styles.layerRight} />
      </View>
      <View style={styles.layerBottom} />
    </View>
  );

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {scanning ? (
        <CameraScanner
          onBarCodeScanned={scannedData || !scanning ? null : handleBarCodeScanned}
          style={[StyleSheet.absoluteFillObject, styles.container]}
          type={selectedCameraType}
        >
          <View style={{flex: 1}}>
            <View style={{ marginTop: 50, paddingRight: 20, flexDirection: 'row', justifyContent: 'flex-end' }}>
              <View style={{ backgroundColor: 'rgba(255, 255, 255, .2)', borderRadius: 10 }}>
                <Pressable
                  onPress={async () => {
                    await updateSetting('selectedCameraType', selectedCameraType == CAMERA_TYPE_BACK ? CAMERA_TYPE_FRONT : CAMERA_TYPE_BACK)
                  }}
                >
                  <View style={{ padding: 10 }}>
                    <MaterialIcons name="flip-camera-ios" size={30} color={selectedCameraType == CAMERA_TYPE_BACK ? "white" : 'black'} />
                  </View>
                </Pressable>
              </View>
            </View>
          </View>
        </CameraScanner>
      ) : (
        <></>
      )}
    </SafeAreaView>
  );
}

export default ScanScreen;
