import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-elements';
import { DataContext } from '../../contexts/DataContext';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { toastError } from '../../helpers/toast';
import ActivityIndicator from '../shared/ActivityIndicator';
import Modal from '../shared/Modal';
import { LocaleContext } from '../../contexts/LocaleContext';

dayjs.extend(customParseFormat);

const EventInfoOverlay = ({ eventId, onClose }) => {
  const { fetchEvent, loadingEvent, findEvent } = React.useContext(DataContext);
  const { t } = React.useContext(LocaleContext);

  const event = findEvent(eventId);

  React.useEffect(() => {
    (async () => {
      try {
        await fetchEvent({ eventId, useCache: false });
      } catch (error) {
        // toastError({
        //   title: 'Unable to get event info',
        //   message: 'There was a problem communicating with the Evey backend, check your connection and try again.',
        // });
      }
    })();
  }, []);

  const InfoItem = ({ title, subtitle }) => (
    <View style={{ paddingTop: 16, paddingLeft: 16, paddingRight: 16}}>
      <Text style={{ width: '100%', fontWeight: '500', fontSize: 16, lineHeight: 20, color: '#000000' }}>{title}</Text>
      <Text style={{ width: '100%', fontSize: 14, lineHeight: 20, color: '#545454' }}>{subtitle}</Text>
      <View style={{borderWidth: 1, borderStyle: 'solid', borderColor: '#E2E2E2', width: '100%', marginTop: 16, borderTopWidth: 0}}></View>
    </View>
  );

  return (
    <Modal
      onClose={() => onClose()}
      heading={event.title}
      subheading={event.date_type !== 'recurring' && event.start_at ? dayjs(event.start_at.slice(0, 23)).format('MMMM D, YYYY h:mm A') : undefined}
      primaryAction={{
        content: t('common.done'),
        onPress: () => onClose(),
      }}
    >
      <InfoItem
        title={`${parseInt(event.checkedin_count).toLocaleString()} / ${parseInt(event.active_attendees_count).toLocaleString()}`}
        subtitle={t('attendees.attendees_currently_checked_in')}
      />
      <InfoItem
        title={`${parseInt(event.has_checkedin_count).toLocaleString()} / ${parseInt(event.active_attendees_count).toLocaleString()}`}
        subtitle={t('attendees.attendees_checked_in')}
      />
      <InfoItem
        title={parseInt(event.total_remaining_inventory).toLocaleString()}
        subtitle={t('attendees.tickets_remaining')}
      />
    </Modal>
  );
};

export default EventInfoOverlay;
