import React from 'react';
import { SafeAreaView, View, ScrollView, RefreshControl, StyleSheet, Pressable, Platform, Keyboard } from 'react-native';
import { Button, Text, Divider } from 'react-native-elements';
import { DataContext } from '../contexts/DataContext';
import { AuthContext } from '../contexts/AuthContext';
import TicketActionSection from '../components/attendee/TicketActionSection';
import TicketInfoSection from '../components/attendee/TicketInfoSection';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { toastError } from '../helpers/toast';
import HeaderBackButton from '../components/shared/HeaderBackButton';
import ActivityIndicator from '../components/shared/ActivityIndicator';
import AttendeeOrderSection from '../components/attendee/AttendeeOrderSection';
import CheckinLog from '../components/attendee/CheckinLog';
import { LocaleContext } from '../contexts/LocaleContext';

dayjs.extend(customParseFormat);

const styles = StyleSheet.create({
  tabSelected: {
    borderColor: '#000000',
  },
  tab: {
    paddingHorizontal: 16,
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: 0,
    flexGrow: 1,
    borderBottomWidth: 5,
    borderStyle: 'solid',
    borderColor: '#E2E2E2',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    ...(Platform.OS === 'web' ? { cursor: 'pointer' } : {}),
    height: 52,
  },
  tabTitle: {
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 16,
    textAlign: 'center',
  },
});

const fetchErrorToast = () => {
  toastError({
    title: 'Unable to get ticket details',
    message: 'There was a problem communicating with the Evey backend, check your connection and try again.',
  });
};

const AttendeeScreen = ({ navigation, route }) => {
  const [initialLoading, setInitialLoading] = React.useState(false);
  const { fetchEvent, fetchAttendee, findAttendee, findEvent, loading, findOrCacheAttendee } = React.useContext(DataContext);
  const { signOut, setAttemptingAttendee } = React.useContext(AuthContext);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const { t, formatDateAndTime } = React.useContext(LocaleContext);

  const { eventId, attendeeId } = route.params;

  const attendee = findAttendee(attendeeId);
  const event = findEvent(eventId);

  React.useEffect(() => {
    Keyboard.dismiss();
    (async () => {
      setInitialLoading(true);
      setAttemptingAttendee({ eventId, attendeeId });
      try {
        await fetchEvent({ eventId, skipNoAuthSignOut: true });
        await fetchAttendee({ eventId, attendeeId, skipNoAuthSignOut: true });
      } catch (error) {
        if (error.name === 'AuthenticationRequiredError') {
          console.log(`Authentication failed, logging out with redirect back to ${JSON.stringify({ eventId, attendeeId })}`);
          // signOut();
          toastError({
            title: t('messages.checkin_no_access'),
            message: t('messages.checkin_no_access_message'),
          });
        } else if (error.name === 'ApiResourceNotFoundError') {
          console.log(`Event or attendee not found: ${error}`);
          toastError({
            title: t('messages.not_found_title'),
            message: t('messages.not_found'),
          });
        } else {
          console.log(`Unable to fetch attendee: ${error}`);
          fetchErrorToast();
        }
      } finally {
        setAttemptingAttendee(null);
        setInitialLoading(false);
      }
    })();
  }, []);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: attendee ? `${t('navigation.attendee')} #${attendee.ticket_number}` : t('navigation.attendee'),
      // headerLeft: () => (
      //   <HeaderBackButton
      //     onPress={()=>{
      //       navigation.reset({
      //         index: 0,
      //         routes: [{ name: 'Attendees', params: { eventId: event.id } }]
      //       });
      //     }}
      //   />
      // ),
    });
  }, [navigation, attendee]);

  const order_attendees = attendee && attendee.order && attendee.order.attendees && attendee.order.attendees.filter((a) => !a.cancelled_at && a.id != attendee.id).map((a) => findOrCacheAttendee(a.id, a));

  const tabs = [
    {
      title: t('attendee.ticket_details'),
      content: (<TicketInfoSection event={event} attendee={attendee} />),
    },
    ...(order_attendees && order_attendees.length > 0 ? [{
      title: `${t('attendee.related_tickets')} (${order_attendees.length})`,
      content: (<AttendeeOrderSection event={event} attendee={attendee} navigation={navigation} />),
    }] : []),
    ...(attendee && attendee.checkin_actions && attendee.checkin_actions.length > 0 ? [{
      title: t('attendee.checkin_log'),
      content: (<CheckinLog event={event} attendee={attendee} />),
    }] : []),
  ];

  if (initialLoading || !attendee || !event) {
    return (
      <SafeAreaView style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
        <ActivityIndicator />
      </SafeAreaView>
    );
  } else {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: '#fff', paddingBottom: 30 }}>
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={async () => {
                try {
                  await fetchEvent({ eventId, useCache: false });
                  await fetchAttendee({ eventId, attendeeId, useCache: false });
                } catch {
                  fetchErrorToast();
                }
              }}
            />
          }
        >
          <View style={{ alignItems: 'center', paddingHorizontal: 37, paddingVertical: 16, backgroundColor: '#F6F6F6' }}>
            <Text style={{ fontWeight: '500', fontSize: 16, lineHeight: 16, color: '#000000' }}>{event.title}</Text>
            {attendee.event_start_at ?
              <Text style={{ fontWeight: '500', fontSize: 12, lineHeight: 16, marginTop: 8, color: '#000000' }}>{formatDateAndTime(attendee.event_start_at.slice(0, 23), event && event.timezone)}</Text>
              :
              null
            }
          </View>
          <View style={{ alignItems: 'center', paddingHorizontal: 24, paddingVertical: 20 }}>
            <Text style={{ fontWeight: 'bold', fontSize: 24, lineHeight: 28, color: '#000000' }}>{attendee.name}</Text>
            <TicketActionSection event={event} attendee={attendee} />
          </View>
          {tabs.length > 1 &&
            <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', paddingHorizontal: 9, marginBottom: 14 }}>
              {tabs.map((tab, index) => (
                <View style={[styles.tab, ...(index === selectedTab ? [styles.tabSelected] : [])]}>
                  <Pressable onPress={() => setSelectedTab(index)} style={{ alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                    <Text style={styles.tabTitle}>{tab.title}</Text>
                  </Pressable>
                </View>
              ))}
            </View>
          }
          {tabs[selectedTab].content}
        </ScrollView>
      </SafeAreaView>
    );
  }
}

export default AttendeeScreen;
