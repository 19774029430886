export class AuthenticationRequiredError extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthenticationRequiredError";
  }
}

export class ApiResourceNotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = "ApiResourceNotFoundError";
  }
}

export class ApiConflict extends Error {
  constructor(message) {
    super(message);
    this.name = "ApiConflict";
  }
}

export class UnknownApiError extends Error {
  constructor(message) {
    super(message);
    this.name = "UnknownApiError";
  }
}
