import React from 'react';
import { SafeAreaView, View, Image } from 'react-native';
import { AuthProvider } from '../contexts/AuthContext';
import { createStackNavigator} from '@react-navigation/stack';
import { NavigationContainer, getStateFromPath as nativeGetStateFromPath } from '@react-navigation/native';
import LoginScreen from '../screens/LoginScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import { AuthContext } from '../contexts/AuthContext';
import TabContainer from '../screens/TabContainer';
import * as SplashScreen from 'expo-splash-screen';
import Toast from 'react-native-toast-message';
import EveyLogoWhite from '../../assets/evey-logo-white.png';
import { title } from '../helpers/document';
import ActivityIndicator from '../components/shared/ActivityIndicator';
import ErrorBoundary from '../components/shared/ErrorBoundary';
import { theme } from '../../app.json';
import { LocaleContext } from '../contexts/LocaleContext';

const Stack = createStackNavigator();

const AppContainer = () => {
  const { isLoggedIn, loading, userEmail } = React.useContext(AuthContext);
  const { t } = React.useContext(LocaleContext);

  // Only use to test splash screen visibility
  // SplashScreen.preventAutoHideAsync();
  // setTimeout(SplashScreen.hideAsync, 10000);

  const linking = {
    // prefixes: ['https://checkin.eveyevents.com',],
    config: {
      screens: {
        Login: "login",
        ForgotPassword: 'forgot_password',
        ResetPassword: 'reset_password/:token',
        Main: {
          screens: {
            "Check-In": {
              initialRouteName: 'Events',
              screens: {
                Events: 'events',
                Attendees: 'events/:eventId/attendees',
                Attendee: 'events/:eventId/attendees/:attendeeId',
              }
            },
            Scan: 'scan',
            Settings: {
              screens: {
                Settings: 'settings',
              },
            }
          },
        },
      }
    },
    getStateFromPath(path, config) {
      const m = path.match(/\/c\/attendees\/([0-9]+)\?event_id=([0-9]+)/);
      if (m) {
        const eventId = m[2];
        const attendeeId = m[1];
        console.log(`Incoming scanned checkin URL for event ${eventId} and attendee ${attendeeId}`);
        return {
          type: 'stack',
          key: 'stack-1',
          routeNames: ['Main'],
          routes: [
            {
              key: 'main-1',
              name: 'Main',
              state: {
                key: 'tab-1',
                routeNames: ['Check-In', 'Scan', 'Settings'],
                routes: [
                  {
                    key: 'check-in-1',
                    name: 'Check-In',
                    state: {
                      key: 'stack-2',
                      routeNames: ['Events', 'Attendees', 'Attendee'],
                      routes: [
                        { key: 'events-1', name: 'Events' },
                        { key: 'attendees-1', name: 'Attendees', params: { eventId } },
                        { key: 'attendee-1', name: 'Attendee', params: { eventId, attendeeId } },
                      ],
                      index: 2,
                    },
                  },
                  { key: 'scan-1', name: 'Scan' },
                  { key: 'settings-1', name: 'Settings' },
                ],
                index: 0,
              },
            },
          ],
          index: 0,
        }
      }
      return nativeGetStateFromPath(path, config);
    },
  };

  if (loading) {
    return (
      <SafeAreaView style={{ flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
        <ActivityIndicator />
      </SafeAreaView>
    );
  }

  const publicScreenOptions = {
    headerStyle: {
      backgroundColor: theme.primaryColor,
    },
    headerTintColor: theme.primaryFontColor,
    headerTitleAlign: 'center',
    headerTitle: (
      <View style={{flex:1, flexDirection:'row', justifyContent:'center' }}>
        <Image source={EveyLogoWhite} style={{ resizeMode: 'contain', width:110, height: 40}} />
      </View>
    ),
  };

  return (
    <NavigationContainer
      linking={linking}
    >
      <ErrorBoundary userEmail={userEmail}>
        <Stack.Navigator
          screenOptions={({ route, navigation }) => {
            if (route?.name === 'Main') {
              return {
                headerShown: false,
              };
            }
          }}
        >
          {!isLoggedIn ? (
            <Stack.Screen
              name="Login"
              component={LoginScreen}
              options={{ ...publicScreenOptions, title: title(t('navigation.login')) }}
            />
          ) : (
            <Stack.Screen name="Main" children={() => <TabContainer userEmail={userEmail} />} />
          )}
          <Stack.Screen
            name="ForgotPassword"
            component={ForgotPasswordScreen}
            options={{ ...publicScreenOptions, title: title(t('navigation.forgot_password')) }}
          />
          <Stack.Screen
            name="ResetPassword"
            component={ResetPasswordScreen}
            options={{ ...publicScreenOptions, title: title(t('navigation.reset_password')) }}
          />
        </Stack.Navigator>
      </ErrorBoundary>
      <Toast ref={(ref) => Toast.setRef(ref)} />
    </NavigationContainer>
  );
}

export default AppContainer;
