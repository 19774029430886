import AsyncStorage from '@react-native-async-storage/async-storage';

const buildKey = (key) => `EveyCheckin:${key}`;

export const storeData = async (key, data) => {
  try {
    await AsyncStorage.setItem(
      buildKey(key),
      data
    );
  } catch (error) {
    console.log(`Error storing data: ${error}`);
  }
};

export const fetchData = async (key) => {
  try {
    const value = await AsyncStorage.getItem(buildKey(key));
    return value;
  } catch (error) {
    console.log(`Error fetching data: ${error}`);
  }
};

export const removeData = async (key) => {
  try {
    await AsyncStorage.removeItem(
      buildKey(key)
    );
  } catch (error) {
    console.log(`Error removing data: ${error}`);
  }
};
