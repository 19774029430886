import React from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { Button, Text, ListItem, Badge } from 'react-native-elements';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';
import { Gravatar, GravatarApi } from 'react-native-gravatar';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { DataContext } from '../../contexts/DataContext';
import { toastError, toastNotify } from '../../helpers/toast';
import { theme } from '../../../app.json';
import { LocaleContext } from '../../contexts/LocaleContext';
import { convertUtcDateToTimezone } from '../../helpers/time';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const styles = StyleSheet.create({
  roundedProfileImage: {
    width:50, height:50, borderWidth:3,
    borderColor:'white', borderRadius:25
  },
  checkinBox: {
    backgroundColor: '#1DB16C',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 80,
  },
  checkinBoxText: {
    color: 'white',
  },
});

const AttendeeAvatar = ({ email }) => (
  <Gravatar options={{
    email,
    parameters: { "size": "200", "d": "mm" },
    secure: true
  }}
  style={styles.roundedProfileImage} />
);

const AttendeeListItem = ({ event, attendee, navigation }) => {
  const [saving, setSaving] = React.useState(false);
  const { checkinAttendee } = React.useContext(DataContext);
  const { t, formatDateAndTime } = React.useContext(LocaleContext);

  const badgeValue = attendee.checkin_limit > 1 ? attendee.checkin_count : <MaterialIcons name='check' size={15} color='white' />;

  let canCheckIn = false;
  if (attendee.checkin_enabled && !attendee.checkin_ticket_expired) {
    if (attendee.checkin_limit == 1) {
      canCheckIn = !attendee.checkedin_at;
    } else {
      canCheckIn = attendee.checkin_count < attendee.checkin_limit;
    }
  }

  let ticketTypeTitle = attendee && attendee.ticket_type.title;
  if (event && event.has_seating && attendee && attendee.seat_label && attendee.seat_label.length > 0) {
    ticketTypeTitle = `${attendee.ticket_type.title} / ${attendee.seat_label}`;
  }

  return (
    <ListItem
      bottomDivider
      onPress={() => {
        // navigation.navigate('Attendee', {
        //   eventId: event.id,
        //   attendeeId: attendee.id,
        // });
        navigation.push('Attendee', {
          eventId: event.id,
          attendeeId: attendee.id,
        });
      }}
    >
      <View>
        <AttendeeAvatar email={attendee.email} />
        {attendee.checkedin_at ?
          <Badge value={badgeValue} status='success' containerStyle={{ position: 'absolute', top: -4, right: -4 }} badgeStyle={{ backgroundColor: theme.checkinColor, borderColor: theme.checkinColor }} />
          : null}
      </View>
      <ListItem.Content>
        <ListItem.Title>{attendee.name}</ListItem.Title>
        <ListItem.Subtitle style={{marginTop:5}}>#{attendee.ticket_number} {ticketTypeTitle}</ListItem.Subtitle>
        {event && event.date_type === 'recurring' && attendee.scheduled_event &&
          <ListItem.Subtitle style={{marginTop:5}}><FontAwesome name="calendar-check-o" size={16} color="rgba(0, 0, 0, 0.54)" /> Scheduled for {formatDateAndTime(attendee.scheduled_event, event && event.timezone)}</ListItem.Subtitle>
        }
        {attendee && attendee.checkedin_at &&
          <ListItem.Subtitle style={{marginTop:5}}><FontAwesome name="check-circle" size={16} color="rgba(0, 0, 0, 0.54)" /> Checked in on {formatDateAndTime(attendee.checkedin_at, event && event.timezone)}</ListItem.Subtitle>
        }
      </ListItem.Content>
      {canCheckIn &&
        <Button
          type="solid"
          title={t('attendee.checkin_action')}
          containerStyle={{ minWidth: 75 }}
          buttonStyle={{ backgroundColor: theme.checkinColor, borderRadius: 50, paddingHorizontal: 10, paddingVertical: 12 }}
          titleStyle={{ fontSize: 14, lineHeight: 16, fontWeight: 'normal', color: '#FFFFFF' }}
          loading={saving}
          onPress={async () => {
            try {
              setSaving(true);
              await checkinAttendee({ eventId: event.id, attendeeId: attendee.id });
              toastNotify({
                title: t('messages.checkin'),
                message: t('messages.checked_in_successfully'),
              });
            } catch (error) {
              console.log(`error: ${error}`);
              toastError({
                title: t('messages.checkin'),
                message: t('messages.checkin_failed'),
              });
            } finally {
              setSaving(false);
            }
          }}
        />
      }
      <ListItem.Chevron />
    </ListItem>
  );
};

export default AttendeeListItem;
