import React from 'react';
import { Platform, View, Pressable, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { HeaderBackButton as NativeHeaderBackButton } from '@react-navigation/stack';

const HeaderBackButton = ({ onPress }) => {
  return (
    Platform.OS === 'web' ? (
      <Pressable
        style={{ cursor: 'pointer' }}
        onPress={onPress}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 11, marginRight: 11 }}>
          <Ionicons name="ios-arrow-back-outline" size={24} color="white" />
          {/* <Text style={{ color: 'white', fontSize: 17, letterSpacing: 0.35, marginLeft: 5 }}>Back</Text> */}
        </View>
      </Pressable>
    ) : (
      <NativeHeaderBackButton
        tintColor='#fff'
        titleStyle={{ color: '#fff' }}
        onPress={onPress}
      />
    )
  );
};

export default HeaderBackButton;
